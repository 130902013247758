import React, { useState, useRef, useEffect, useContext } from 'react'
import { Card, DataTable } from 'react-native-paper'
import { View, Text, ScrollView, StyleSheet, TouchableOpacity} from "react-native";
import axios from 'axios'
import Background from '../../components/Background'
import SPRP0011Params from './SPRP0011Params'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
// import "jszip/dist/jszip.min.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import $ from "jquery";

import {
  DialogCostum
} from '../../components'

import { LoginContext } from '../../contexts/GlobalState'
import { currencyFormat } from '../../utils/currency';

export default function SPRP0011({ route, navigation }){
  $.DataTable = require('datatables.net')
  const tableRef = useRef()
  const tableName = "table"+route.name

  const homeprm = navigation.getState();
  const home = homeprm.routes[0].state.routes[0].params;
  let table;

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [dataGrid, setDataGrid] = useState()
  const [office, setOffice] = useState()
  const [getdate, setDate] = useState()
  const [year, setYear] = useState()

  const hideDialog = () => {
    setVisible(false)
  }

  const exportTo = (exp) =>{
    if(exp==='EXCEL'){
      $( `#${tableName}_wrapper` ).find('.buttons-excel').trigger('click');
    }
    if(exp==='PDF'){
      $( `#${tableName}_wrapper` ).find('.buttons-pdf').trigger('click');
    }
  }

  const login = useContext(LoginContext)  
  useEffect(() => {
    // setTimeout( function () {
    //   $(`#${tableName}`).DataTable().columns.adjust();
    // }, 500)
  }, [login]);
  useEffect(() => {
    const date = new Date(home.oprdt)
    setYear(date.getFullYear())    
    setOffice(home.brncd+' - '+home.brnm);
    postSubmit({
      brnm:home.brnm,
      office:{value: home.brncd},
      year:{value: date.getFullYear()},
    })
  }, []);

  const postSubmit = (v) => {
    try {
      axios.post(process.env.REACT_APP_URL_API + "SPRP0011/read",  {
        method:"getreport",
        office:v.office.value,
        year:v.year.value,
      }, {timeout: 120000})
      .then(res => {
        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{
          setOffice(v.office.value+' - '+v.brnm)
          setYear(v.year.value)
          setDataGrid(res.data.data)

          const title = 'LAPORAN KOMPARASI 12 BULAN TERAKHIR'+'\n'+ login.bankname+'\n'+ v.office.value+' - '+v.brnm.toUpperCase()+'\n'+v.year.value
          const ly = Number(v.year.value)-1;

          table = $(`#${tableName}`).DataTable(
            {
              data: res.data.data,
              scrollY:"360px",
              scrollX:true,
              scrollCollapse: true,
              fixedHeader: true,
              dom: "lBfrtip",
              buttons: [
                {
                  extend: "excelHtml5",
                  title: title
                },
                {
                  extend: 'pdfHtml5',
                  pageSize: 'LEGAL',
                  title: title,
                  orientation:'landscape',
                  customize: function (doc) { 
                    doc.styles.title = {
                      fontSize: '12',
                      alignment: 'center'
                    }   
                    var rowCount = doc.content[1].table.body;
                    for (let i = 0; i < rowCount.length; i++) {
                      rowCount[i][1].alignment = 'right';
                      rowCount[i][2].alignment = 'right';
                      rowCount[i][3].alignment = 'right';
                      rowCount[i][4].alignment = 'right';
                      rowCount[i][5].alignment = 'right';
                    }
                  }
                },
              ],
              columnDefs: [
                { targets: [1,2,3,4,5,6,7,8,9,10,11,12,13], className: 'dt-body-right' },
                { targets: [1,2,3,4,5,6,7,8,9,10,11,12,13], className: 'dt-head-right' }
              ],
              columns: [
                { title: "DETAIL"},
                { title: 'DES - '+ly},
                { title: 'JAN - '+v.year.value},
                { title: 'FEB - '+v.year.value},
                { title: 'MAR - '+v.year.value},
                { title: 'APR - '+v.year.value},
                { title: 'MEI - '+v.year.value},
                { title: 'JUN - '+v.year.value},
                { title: 'JUL - '+v.year.value},
                { title: 'AGU - '+v.year.value},
                { title: 'SEP - '+v.year.value},
                { title: 'OKT - '+v.year.value},
                { title: 'NOV - '+v.year.value},
                { title: 'DES - '+v.year.value}
              ],
              oLanguage:{
                sSearch:"<span>Pencarian </span> _INPUT_",
                sLengthMenu:"Tampil _MENU_",
                sZeroRecords:"Tidak ada data untuk ditampilkan.",
                sInfo:"Total _TOTAL_ baris (_START_ - _END_)",
                sInfoFiltered: "", 
                sInfoEmpty: "Menampilkan 0 baris",
                oPaginate:{
                  sNext:">",
                  sPrevious:"<"
                }
              },
              searching:{
                regex:1
              },
              ordering: false,
              pageLength: -1,
              lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "Semua"] ],
              destroy: true,  // I think some clean up is happening here
            }
          )
          
          $('.dt-buttons').hide();
        // Extra step to do extra clean-up.
          return function() {
              console.log("Table destroyed")
              table.destroy()
          }
        }
      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
  }

  return (
    <Background isVisibleBg={true}>
      <SPRP0011Params home={home} export={exportTo} action={postSubmit} progid={route.name} />
      <Card style={{backgroundColor: 'rgba(204, 204, 255, 0.2)'}}>
      <Card.Content>
          <View style={{alignItems:'center', padding:5}}>
            <Text style={{fontSize:14, fontWeight:'bold'}}>LAPORAN KOMPARASI 12 BULAN TERAKHIR</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{login.bankname}</Text>
            <Text style={{fontSize:14, fontWeight:'bold', textTransform: 'uppercase'}}>{office}</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{year}</Text>
          </View>

          <table
            style={{
              marginTop:'10px',
              fontSize: '14px',
              fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif"
            }}
            className="display"
            width="100%"
            id={tableName}
            ref={tableRef}
          >
          
          </table>
        </Card.Content>
      </Card>
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    width: '100%',
  },
  row:{
    width: '60%'
  },
  bold_row:{
    fontWeight:'bold',
    // backgroundColor: '#F88379'
  }
});