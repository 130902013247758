import React, { useState } from 'react'
import { ScrollView, View, StyleSheet, KeyboardAvoidingView } from "react-native"
import { Divider, Card, Button, TextInput as Input } from 'react-native-paper'
import axios from 'axios'
import { Picker } from '@react-native-picker/picker'
import DropDown from "react-native-paper-dropdown"

import { theme } from '../../core/theme'
import {
  TextInput,
  DialogCostum,
  Loader,
} from '../../components'
import Background from '../../components/Background'

export default function SPRP0018({ route, navigation }) {
  const homeprm = navigation.getState();
  const home = homeprm.routes[0].state.routes[0].params;
  const date = new Date(home.oprdt)
  const [errorMessage, setErrorMessage] = useState(null)
  const [visible, setVisible] = useState(false)
  const [spin, setSpin] = useState()

  const hideDialog = () => {
    setVisible(false)
  }

  const [year, setYear] = useState({ value: date.getFullYear(), error: '' })
  const [metode, setMetode] = useState({ value: 'SPRP0018A', error: '' })
  const [grup, setGrup] = useState({ value: 1, error: '' })
  
  const [metodeDropDown, setMetodeDropDown] = useState(false);
  const [grupDropDown, setGrupDropDown] = useState(false);
  const metodes = { 
    list:[
      { label: "PD Net Flow", value: 'SPRP0018A' },
      // not support
      // { label: "PD Migration", value: 'SPRP0018B' },
    ]
  }

  const grups = { 
    list:[
      { label: "Jenis Penggunaan", value: 1 },
      { label: "Kategori Usaha", value: 2 },
    ]
  }

  const onCancel = () => {    
    setYear({ value: date.getFullYear(), error: '' })
    setGrup({ value: 1, error: '' })
    setMetode({ value:'SPRP0018A', error: ''})
  }

  const yearValidator = (v) => {
    if (!v) return "Periode Tahun tidak boleh kosong."
    if (parseInt(v)<1000 || parseInt(v)>9999 || isNaN(parseInt(v))) return "Periode Tahun tidak valid."
    if (v>date.getFullYear()) return "Periode Tahun tidak boleh lebih besar dari "+date.getFullYear()+"."
    return ''
  }

  const metodeValidator = (x) => {
    if (!x) return "Metode PD tidak boleh kosong."
    return ''
  }

  const metodeOnChange = (val) => {
    setMetode({ value: val, error: '' })
    return '' 
  }

  const grupValidator = (y) => {
    if (!y) return "Grup PD tidak boleh kosong."
    return ''
  }

  const onSubmit = async () => {
    const yearError = yearValidator(year.value)
    const metodeError = metodeValidator(metode.value)
    const grupError = grupValidator(grup.value)

    setYear({ ...year, error: yearError })
    setMetode({ ...metode, error: metodeError })
    setGrup({ ...grup, error: grupError })
    if (yearError || metodeError || grupError) {
      return
    }

    setSpin(true)
    
    try {
      await axios.post(process.env.REACT_APP_URL_API + "SPRP0018/read",  {
        year:year.value,
        grup:grup.value,
        method:metode.value
      }, {timeout: 600000})
      .then(res => {
        
        setSpin(false)

        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{
          var linkSource = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data?.data?.content;
          var downloadLink = document.createElement("a");

          downloadLink.href = linkSource;
          downloadLink.download = res.data?.data?.filenm;
          downloadLink.click();
          
          if(res.data?.data?.path) window.location.href = process.env.REACT_APP_URL_API + res.data.data.path;
        }
      })
      .catch(err => {
        setSpin(false)
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      setSpin(false)
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }

  }

  return (
    <Background>
      <ScrollView>
        <KeyboardAvoidingView style={styles.container} behavior="padding">
          <View>
            <Card style={styles.gridView}>
              <Card.Title title="Parameter" />
              <Divider />
              <Card.Content>
                <View>
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Metode PD"}
                      mode={"outlined"}
                      value={metode.value}
                      setValue={(text) => metodeOnChange(text)}
                      list={metodes.list}
                      error={!!metode.error}
                      errorText={metode.error}
                      visible={metodeDropDown}
                      showDropDown={() => setMetodeDropDown(true)}
                      onDismiss={() => setMetodeDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={metodeDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {metode.error ? <Text style={styles.error}>{metode.error}</Text> : null}
                  </View>
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Grup PD"}
                      mode={"outlined"}
                      value={grup.value}
                      setValue={(text) => setGrup({ value: text, error: '' })}
                      list={grups.list}
                      error={!!grup.error}
                      errorText={grup.error}
                      visible={grupDropDown}
                      showDropDown={() => setGrupDropDown(true)}
                      onDismiss={() => setGrupDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={grupDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {grup.error ? <Text style={styles.error}>{grup.error}</Text> : null}
                    </View>
                  <TextInput 
                    label="Periode Tahun"
                    returnKeyType="next"
                    value={year.value}
                    onChangeText={(text) => setYear({ value: text.replace(/[^0-9]/g, ''), error: '' })}
                    // error={!!year.error}
                    errorText={year.error}
                  />
                </View>
              </Card.Content>
              <Divider />
              <Card.Actions style={{justifyContent: 'right'}}>
                <Button onPress={onCancel}>Batal</Button>
                <Button onPress={onSubmit}>Cetak</Button>
              </Card.Actions>
            </Card>
            
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
      
      { spin && <Loader size="large" animating={spin} />}
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  // container: {
  //   flex: 1,
  //   padding: 10,
  //   // maxWidth: 420,
  //   width: '100%',
  //   alignSelf: 'center',
  // },
  container: {
    flex: 1,
    paddingTop: 50,
    padding: 10,
    maxWidth: 340,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridView: {
    marginTop: 10,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
  dropDown: {
    marginVertical: 12,
    // flex: 1,
    // left:-100 , position: 'absolute',
  },
});