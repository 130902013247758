// import React from 'react'
import { AppRegistry } from 'react-native'
import { Provider } from 'react-native-paper'
import { theme } from './src/core/theme'
import Container from './src/navigation/Container'
import { gestureHandlerRootHOC } from 'react-native-gesture-handler'
import { myfolder } from './app.config';

export default function App() {
  // console.log(__DEV__)
  return (
    <Provider theme={theme}>
      <Container />
    </Provider>
  )
}

// "project" is the name of the main react-native Folder
// the second argument is an arrow function 
// returning myComponent defined above
AppRegistry.registerComponent(myfolder, () => gestureHandlerRootHOC(App));
  
// runApplication() loads the javascript bundle and runs the app.
// AppRegistry.runApplication(myfolder, {
//     rootTag: document.getElementById("root")
// });