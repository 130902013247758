import {
  HomeDashboard,
  SPRP0001,
  SPRP0002,
  SPRP0003,
  SPRP0004,
  SPRP0005,
  SPRP0006,
  SPRP0007,
  SPRP0008,
  SPRP0009,
  SPRP0010,
  SPRP0011,
  SPRP0012,
  SPRP0013,
  SPRP0014,
  SPRP0015,
  SPRP0016,
  SPRP0017,
  SPRP0018,
} from '../screens/mdashboard'

export function routes (progid) {
  const modules = {
    HomeDashboard,
    SPRP0001,
    SPRP0002,
    SPRP0003,
    SPRP0004,
    SPRP0005,
    SPRP0006,
    SPRP0007,
    SPRP0008,
    SPRP0009,
    SPRP0010,
    SPRP0011,
    SPRP0012,
    SPRP0013,
    SPRP0014,
    SPRP0015,
    SPRP0016,
    SPRP0017,
    SPRP0018,
  };

  return modules[progid]?modules[progid]:modules.HomeDashboard;
}