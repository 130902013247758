import React, { useState, useRef, useEffect, useContext } from 'react'
import { Card } from 'react-native-paper'
import { View, Text, StyleSheet, TouchableOpacity, ScrollView} from "react-native";
import axios from 'axios'
import Background from '../../components/Background'
import CustomParams from './CustomParams'
import { theme } from '../../core/theme'
import FontAwesome from '@expo/vector-icons/FontAwesome'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import $ from "jquery";

const jzip = require( 'jszip/dist/jszip.js');
window.JSZip = jzip;

import {
  DialogCostum,
  Loader,
} from '../../components'

import { LoginContext } from '../../contexts/GlobalState'

export default function SPRP0013({ route, navigation }){
  $.DataTable = require('datatables.net')
  $.fn.dataTable.ext.errMode = 'none';

  const tableRef = useRef()
  const tableName = "table"+route.name
  const tableRefTungg = useRef()
  const tableNameTungg = "tungg_"+tableName
  const tableRefRinci = useRef()
  const tableNameRinci = "rinci_"+tableName

  const homeprm = navigation.getState();
  const home = homeprm.routes[0].state.routes[0].params;
  let table;

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [office, setOffice] = useState()
  const [getdate, setDate] = useState()
  const [tbodyColumns, setTbodyColumns] = useState()
  const [tbodyColumnsTungg, setTbodyColumnsTungg] = useState()
  const [tbodyColumnsRinci, setTbodyColumnsRinci] = useState()
  const [activeTable, setActiveTable] = useState()
  const [submitDate, setSubmitDate] = useState()
  const [submitLastDate, setSubmitLastDate] = useState()
  const [submitLastMDate, setSubmitLastMDate] = useState()
  const [submitOffice, setSubmitOffice] = useState()
  const [submitBrnm, setSubmitBrnm] = useState()
  const [reportKey, setReportKey] = useState()
  const [reportDate, setReportDate] = useState()
  const [codeRinci, setCodeRinci] = useState()
  const [spin, setSpin] = useState()
  const [reportName, setReportName] = useState([
    "LAPORAN TREN NPL 2 BULAN TERAKHIR",
    "LAPORAN REKAP KELOMPOK TUNGGAKAN",
    "LAPORAN REKAP KELOMPOK TUNGGAKAN RINCI",
  ])
  
  const hideDialog = () => {
    setVisible(false)
  }

  const exportTo = (exp) =>{

    const id = activeTable ? (activeTable==='tungg'? tableNameTungg: tableNameRinci): tableName;

    if(exp==='EXCEL'){
      $( `#${id}_wrapper` ).find('.buttons-excel').trigger('click');
    }
    if(exp==='PDF'){
      $( `#${id}_wrapper` ).find('.buttons-pdf').trigger('click');
    }
  }

  const login = useContext(LoginContext)  
  useEffect(() => {}, [login]);

  useEffect(() => {
    setOffice(home.brncd+' - '+home.brnm);
    const date = new Date(home.oprdt)
    postSubmit({
      brnm:home.brnm,
      office:{value: home.brncd},
      getdate:{value: date}
    })
  }, []);
  
  useEffect(() => {
    setReportDate(activeTable==="tungg"?submitLastDate:reportDate)
    let key
    if(activeTable==="rinci"){
      key=2
    }else if(activeTable==="tungg"){
      key=1
    }else{
      key=0
    }
    setReportKey(key)
  }, [activeTable]);
  
  const links = (v) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setActiveTable(v?.table)
          setCodeRinci(v?.code && v?.code)
          postSubmit({
            brnm:submitBrnm,
            office:{value: submitOffice},
            getdate:{value: v?.date},
            posisi:v?.posisi,
            code:v?.code,
            table:v?.table,
            group:v?.group
          })
        }}
      >
        <Text style={styles.link}>{v.text}</Text>
      </TouchableOpacity>
    );
  }

  const postSubmit = (v) => {
    setSpin(true)
    const date = v.getdate.value
    setSubmitDate(date)
    setSubmitOffice(v.office.value)
    setSubmitBrnm(v.brnm)
    setOffice(v.office.value+' - '+v.brnm)
    setDate(v?.table?date:date.toISOString().slice(0, 10))
    !v?.table && setActiveTable("")

    let id = tableName
    let bodyRight = [1,2,3,4,5,6,7,8,9]
    let headLen =2
    // let rTitle = "LAPORAN TREN NPL 2 BULAN TERAKHIR"
    let bodyCenter
    let repKey

    if(!v?.table){
      setReportKey(0)
      repKey = 0
    }else{
      if(v?.table==='tungg'){
        // rTitle = "LAPORAN REKAP KELOMPOK TUNGGAKAN"
        setReportKey(1)
        repKey = 1
        bodyRight = [1,2,3,4,5,6,7]
        id = tableNameTungg
        headLen = 1
      }else{
        // rTitle = "LAPORAN REKAP KELOMPOK TUNGGAKAN RINCI"
        setReportKey(2)
        repKey = 2
        bodyRight = [3,4,5,7,9]
        bodyCenter = [6,8]
        id = tableNameRinci
        headLen = 0
      }
    }
    
    try {
      axios.post(process.env.REACT_APP_URL_API + "SPRP0013/read",  {
        method:"getreport",
        table:v?.table,
        office:v.office.value,
        code:v?.code,
        group:v?.group,
        getdate:date
      }, {timeout: 120000})
      .then(res => {
        setSpin(false)
        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{

          if(!v?.table){
            setTbodyColumns(res.data?.data?.columns)
          }else{
            if(v?.table==='tungg')
              setTbodyColumnsTungg(res.data?.data?.columns)
            else
              setTbodyColumnsRinci(res.data?.data?.columns)
          }

          res.data?.data?.lastdate && setSubmitLastDate(res.data?.data?.lastdate)
          res.data?.data?.lastmdate && setSubmitLastMDate(res.data?.data?.lastmdate)
          setReportDate(res.data?.data?.reportdate)

          const title = reportName[repKey]+'\n'+
            login.bankname+'\n'+ 
            v.office.value+' - '+v.brnm?.toUpperCase()+'\n'+
            (submitLastDate?submitLastDate:res.data?.data?.lastdate)

          table = $(`#${id}`).DataTable(
            {
              // data: columns,
              scrollY:"360px",
              scrollX:true,
              dom: "B",
              buttons: [
                {
                  extend: "excelHtml5",
                  title: title,
                  // messageTop: title,
                  header:false,
                  customize: function ( xlsx ) {
                    var sheet = xlsx.xl.worksheets['sheet1.xml'];
                    //Bold Header Row
                    //51 center
                    // $('row:first c', sheet).attr('s',55);
                    $('row[r=2] c', sheet).attr('s',2);
                    if(headLen===2){
                      $('row[r=3] c', sheet).attr('s',2);
                      $('row[r=4] c', sheet).attr('s',2);
                    }else if(headLen===1){
                      $('row[r=3] c', sheet).attr('s',2);
                    }
                  },
                  customizeData: function(data){
                    data.body.unshift(data.header);                    
                    for(let k=headLen; k > 0; k--){
                      var headTr = [];
                      $(`#${id}`).find("thead>tr:nth-child("+k+")>th").each(function(i, e) {
                          headTr.push(e.innerHTML);
                      });
                      data.body.unshift(headTr)
                    }
                  }
                },
                {
                  extend: 'pdfHtml5',
                  orientation:'landscape',
                  pageSize: 'LEGAL',
                  title: title,
                  // messageTop: ,
                  customize: function (doc) {                         
                    doc.styles.title = {
                      fontSize: '12',
                      alignment: 'center'
                    }
                    
                    doc.content[1].table.headerRows = headLen;
                    for(let k=headLen; k > 0; k--){
                      var headTr = [];
                      $(`#${id}`).find("thead>tr:nth-child("+k+")>th").each(
                        function(i, e) {
                          headTr.push({
                            text: e.innerHTML,
                            style: "tableHeader",
                            colSpan: e.getAttribute("colSpan")
                          });
                      });
                      doc.content[1].table.body.unshift(headTr);
                    }

                    var rowCount = doc.content[1].table.body;
                    for (let i = 0; i < rowCount.length; i++) {
                      
                      rowCount[i][3].alignment = 'right';
                      rowCount[i][4].alignment = 'right';
                      rowCount[i][5].alignment = 'right';
                      rowCount[i][6].alignment = 'right';
                      rowCount[i][7].alignment = 'right';

                      if(!v?.table){
                        rowCount[i][1].alignment = 'right';
                        rowCount[i][2].alignment = 'right';
                        rowCount[i][8].alignment = 'right';
                        rowCount[i][9].alignment = 'right';
                      }else if(v?.table==='rinci'){
                        rowCount[i][7].alignment = 'center';
                        rowCount[i][8].alignment = 'right';
                        rowCount[i][9].alignment = 'center';
                      }else{
                        rowCount[i][1].alignment = 'right';
                        rowCount[i][2].alignment = 'right';
                      }
                    }
                  }
                },
              ],
              columnDefs: [
                { targets: bodyRight, className: 'dt-body-right' },
                { targets: bodyCenter, className: 'dt-body-center' },
                { targets: bodyRight, className: 'dt-head-right' }
              ],
              // columns: res.data.data.headers,
              searching:false,
              ordering: false,
              pageLength: -1,
              // destroy: true,
            }
          )

          $('.dt-buttons').hide();
            
        // Extra step to do extra clean-up.
          return function() {
            table.destroy()
          }
        }
        
      })
      .catch(err => {
        setSpin(false)
        console.log(err)
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) )
        setVisible(true)
      });
      
    } catch (err) {
      setSpin(false)
      console.log(err)
      setErrorMessage('Server Luring')
      setVisible(true)
    }
  }

  const headright = "dt-head-right"
  const bodyright = "dt-body-right"
  const tablefont = "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif"

  return (
    <Background isVisibleBg={true}>

      <CustomParams home={home} export={exportTo} action={postSubmit} progid={route.name} />

      <Card style={{backgroundColor: 'rgba(204, 204, 255, 0.2)'}}>
        <Card.Content>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity
              onPress={() => {
                setActiveTable(activeTable==="rinci"?"tungg":"")
              }}
            >
              <Text>
                {activeTable && <FontAwesome name="arrow-left" size={16}></FontAwesome>}
              </Text>
            </TouchableOpacity>
          </View>

          <View style={{alignItems:'center', padding:5}}>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{reportName[reportKey]}</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{login.bankname}</Text>
            <Text style={{fontSize:14, fontWeight:'bold', textTransform: 'uppercase'}}>{office}</Text>
            <Text style={{fontSize:14, fontWeight:'bold', textTransform: 'uppercase'}}>{reportDate}</Text>
          </View>

          <table
            style={{
              fontSize: '14px',
              fontFamily: {tablefont},
              display: !activeTable?'':'none'
            }}
            className="display"
            width="100%"
            id={tableName}
            ref={tableRef}
          >
            <thead>
              <tr>
                <th></th>
                <th className={headright}>Posisi Bulan</th>
                <th className={headright}>Lalu</th>
                <th className={headright}>{submitLastMDate}</th>
                <th className={headright}>Posisi Bulan</th>
                <th className={headright}>Berjalan</th>
                <th className={headright}>{submitLastDate}</th>
                <th className={headright}>Pertumbuhan</th>
                <th className={headright}>Bulan</th>
                <th className={headright}>Berjalan</th>
              </tr>
              <tr>
                <th></th>
                <th className={headright}>Jumlah Rek</th>
                <th className={headright}>Baki Debet</th>
                <th className={headright}>% Baki Debet</th>
                <th className={headright}>Jumlah Rek</th>
                <th className={headright}>Baki Debet</th>
                <th className={headright}>% Baki Debet</th>
                <th className={headright}>Jumlah Rek</th>
                <th className={headright}>Baki Debet</th>
                <th className={headright}>% Baki Debet</th>
              </tr>
              <tr>
                <th>Kolektibilitas</th>
                <th>a</th>
                <th>b</th>
                <th>c= (b/total b)*100</th>
                <th>d</th>
                <th>e</th>
                <th>f= (e/total e)*100</th>
                <th>g= d-a</th>
                <th>h= e-b</th>
                <th>i= (h/b)*100</th>
              </tr>
            </thead>
          
            <tbody>
              {
                tbodyColumns && tbodyColumns.map(function(o, i){
                  if(typeof(o)==='string')
                    return <tr key={i}>
                      <td>{o}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  else if (o[0]==="TOTAL")
                    return <tr key={i}>
                      <td><b>{o[0]}</b></td>
                      <td className={bodyright}><b>{o[1]}</b></td>
                      <td className={bodyright}><b>{o[2]}</b></td>
                      <td className={bodyright}><b>{o[3]}</b></td>
                      <td className={bodyright}><b>{o[4]}</b></td>
                      <td className={bodyright}><b>{o[5]}</b></td>
                      <td className={bodyright}><b>{o[6]}</b></td>
                      <td className={bodyright}><b>{o[7]}</b></td>
                      <td className={bodyright}><b>{o[8]}</b></td>
                      <td className={bodyright}><b>{o[9]}</b></td>
                    </tr>
                  else
                    return <tr key={i}>
                      <td>{o[0]}&nbsp;</td>
                      <td className={bodyright}>{o[1]}</td>
                      <td className={bodyright}>{links({text:o[2], table:'tungg', date:submitLastMDate, kolek:o[0], code:o[10], posisi:'BERJALAN'})}</td>
                      <td className={bodyright}>{o[3]}</td>
                      <td className={bodyright}>{o[4]}</td>
                      <td className={bodyright}>{links({text:o[5], table:'tungg', date:submitLastDate, kolek:o[0], code:o[10], posisi:"LALU"})}</td>
                      <td className={bodyright}>{o[6]}</td>
                      <td className={bodyright}>{o[7]}</td>
                      <td className={bodyright}>{o[8]}</td>
                      <td className={bodyright}>{o[9]}</td>
                    </tr>
                })
              }
            </tbody>
          </table>
          
          {/* <View style={{height:'360px'}}>
            <ScrollView> */}
              <table
                style={{
                  fontSize: '14px',
                  fontFamily: {tablefont},
                  display: activeTable ==='tungg'?'':'none'
                }}
                className="display"
                width="100%"
                id={tableNameTungg}
                ref={tableRefTungg}
              >
                <thead style={{position: 'sticky', background: '#EBEBF5', top:0}}>
                  <tr>
                    <th></th>
                    <th className={headright}>Jumlah Rek</th>
                    <th className={headright}>Baki Debet</th>
                    <th className={headright}>% Baki Debet</th>
                    <th className={headright}>Tunggakan Pokok</th>
                    <th className={headright}>Tunggakan Bunga</th>
                    <th className={headright}>Total Tunggakan</th>
                    <th className={headright}>% Total Tunggakan</th>
                  </tr>
                  <tr>
                    <th>Kelompok Tunggakan</th>
                    <th>a</th>
                    <th>b</th>
                    <th>c= (b/total b)*100</th>
                    <th>d</th>
                    <th>e</th>
                    <th>f= d+e</th>
                    <th>g= (f/total f)*100</th>
                  </tr>
                </thead>
              
                <tbody>
                  {
                    tbodyColumnsTungg && tbodyColumnsTungg.map(function(o, i){
                      if(typeof(o)==='string')
                        return <tr key={i}>
                          <td>{o}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      else if (o[0]==="TOTAL")
                        return <tr key={i}>
                          <td><b>{o[0]}</b></td>
                          <td className={bodyright}><b>{o[1]}</b></td>
                          <td className={bodyright}><b>{o[2]}</b></td>
                          <td className={bodyright}><b>{o[3]}</b></td>
                          <td className={bodyright}><b>{o[4]}</b></td>
                          <td className={bodyright}><b>{o[5]}</b></td>
                          <td className={bodyright}><b>{o[6]}</b></td>
                          <td className={bodyright}><b>{o[7]}</b></td>
                        </tr>
                      else
                        return <tr key={i}>
                          <td>{links({text:o[0], code:codeRinci, group:o[8], table:'rinci', date:getdate})}</td>
                          <td className={bodyright}>{o[1]}</td>
                          <td className={bodyright}>{o[2]}</td>
                          <td className={bodyright}>{o[3]}</td>
                          <td className={bodyright}>{o[4]}</td>
                          <td className={bodyright}>{o[5]}</td>
                          <td className={bodyright}>{o[6]}</td>
                          <td className={bodyright}>{o[7]}</td>
                        </tr>
                    })
                  }
                </tbody>
              </table>
            {/* </ScrollView>
          </View> */}

          <View style={{height:'360px'}}>
            <ScrollView>
              <table
                style={{
                  fontSize: '14px',
                  fontFamily: {tablefont},
                  display: activeTable ==='rinci'?'':'none'
                }}
                className="display"
                width="100%"
                id={tableNameRinci}
                ref={tableRefRinci}
              >
                <thead style={{position: 'sticky', background: '#EBEBF5', top:0}}>
                  <tr>
                    <th>No</th>
                    <th>No Rekening</th>
                    <th>Jenis Produk</th>
                    <th>Plafond</th>
                    <th>Baki Debet</th>
                    <th>Tunggakan Pokok</th>
                    <th className="dt-head-center">Hari tgkp</th>
                    <th>Tunggakan Bunga</th>
                    <th className="dt-head-center">Hari tgkb</th>
                    <th>Total Tunggakan</th>
                  </tr>
                </thead>
              
                <tbody>
                  {
                    tbodyColumnsRinci && tbodyColumnsRinci.map(function(o, i){
                      if(typeof(o)==='string')
                        return <tr key={i}>
                          <td></td>
                          <td>{o}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      else
                        return <tr key={i}>
                          <td>{o[0]}</td>
                          <td>{o[1]}</td>
                          <td>{o[2]}</td>
                          <td className={bodyright}>{o[3]}</td>
                          <td className={bodyright}>{o[4]}</td>
                          <td className={bodyright}>{o[5]}</td>
                          <td className="dt-body-center">{o[6]}</td>
                          <td className={bodyright}>{o[7]}</td>
                          <td className="dt-body-center">{o[8]}</td>
                          <td className={bodyright}>{o[9]}</td>
                        </tr>
                    })
                  }
                </tbody>
              </table>
            </ScrollView>
          </View>

        </Card.Content>
      </Card>

      { spin && <Loader size="large" animating={spin} />}
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
});