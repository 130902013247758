import React, { useState, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const LoginContext = React.createContext(null);

export default function GlobalState({ children }) {
    const [signStorage, setSignStorage] = useState();
  
    const readData = async () => {
      try {
        const sign = await AsyncStorage.getItem('@signStorage');
    
        if (sign !== null) {
            setSignStorage(JSON.parse(sign));
        }
      } catch (e) {
        console.log(e);
      }
    };
  
    useEffect(() => {
      readData();
    }, []);
      
    return (
        <LoginContext.Provider value={{ ...signStorage }}>
          {children}
        </LoginContext.Provider>
    )
}