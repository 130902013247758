import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Image, TouchableOpacity, StyleSheet, Platform, Dimensions} from 'react-native'
import axios from 'axios'
import { Avatar, Divider, List, Badge } from 'react-native-paper'
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer'
import Animated from 'react-native-reanimated'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import { theme } from '../core/theme'

import {
  AccountScreen,
  InboxScreen,
  LogoutScreen,
  SessionScreen,
} from '../screens'

import GlobalState from '../contexts/GlobalState'
import { LoginContext } from '../contexts/GlobalState'
import NonBottomStack from './NonBottomStack'
import { routes } from './routes'

const Drawer = createDrawerNavigator();
// const Stack = createStackNavigator()

function CustomDrawer({ navigation, listMenu }) {
  
  // const [expanded, setExpanded] = useState(false);
  // const handlePress = () => setExpanded(!expanded)
  
  const context = useContext(LoginContext)  
  useEffect(() => {}, [context]);
  const [pressed, setPressed] = useState('home');

  return (
    <View style={{flex: 1}}>
      <DrawerContentScrollView contentContainerStyle={{ flex: 1, display: 'block', paddingTop: 0, bottom: 0 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
            backgroundColor: theme.colors.secondary,
          }}
        >
          <View>
            <Text>{context.fullname}</Text>
            <Text>{context.branch+' - '+context.officename}</Text>
          </View>
          <Avatar.Text size={45} label={context.fullname?context.fullname.substring(0, 2):""} />
          {/* <Image
            source={{
              uri: '',
            }}
            style={{ width: 60, height: 60, borderRadius: 30 }}
          /> */}
        </View>
        <Divider />

        {/* <DrawerItemList {...props} /> */}

        <Animated.View>
          <List.Section title="">
            <List.Item title="BERANDA"
              left={props => <List.Icon {...props} icon="home" />}
              style={{ backgroundColor : pressed == 'home' ? theme.colors.secondary : '' }}
              titleStyle={{fontSize:12}}
              onPress={() => {
                setPressed('home')
                navigation.navigate('Index')
              }}
            />
            <Divider />
            <List.Item title="AKUN"
              left={props => <List.Icon {...props} icon="account" />}
              style={{ backgroundColor : pressed == 'akun' ? theme.colors.secondary : '' }}
              titleStyle={{fontSize:12}}
              onPress={() => {
                setPressed('akun')
                navigation.navigate('Account')
              }}
            />
            <Divider />
            <List.Item title="SESI"
              left={props => <List.Icon {...props} icon="clock-time-nine-outline" />}
              style={{ backgroundColor : pressed == 'sesi' ? theme.colors.secondary : '' }}
              titleStyle={{fontSize:12}}
              onPress={() => {
                setPressed('sesi')
                navigation.navigate('Session')
              }}
            />
            <Divider />
            {
              listMenu && listMenu.map(function(o, i){
                var subMenu=o.seq
                if(o.sub){
                  return <List.Accordion
                    title={o.caption}
                    key={i} 
                    titleStyle={{fontSize:12}}
                  >
                  <Divider />
                    {
                      listMenu.map(function(od, id){
                        if(o.id === od.parent){
                          subMenu = od.seq
                          return <List.Item key={id} title={od.caption} 
                          style={{ backgroundColor : pressed == od.module ? theme.colors.secondary : '' }}
                          titleStyle={{fontSize:12}}
                            onPress={() => {
                              setPressed(od.module)
                              navigation.navigate(od.module)
                            }}
                          />
                        }
                      })
                    }
                  </List.Accordion>
                  
                }else if(subMenu!==o.seq){
                    return <List.Item key={i} title={o.caption}
                      titleStyle={{fontSize:12}}
                      onPress={() => {
                        navigation.navigate(o.module)
                      }}
                    />
                }
              })
            }          
            <List.Item title="KELUAR"
              left={props => <List.Icon {...props} icon="logout-variant" />}
              style={{ backgroundColor : pressed == 'out' ? theme.colors.secondary : '' }}
              titleStyle={{fontSize:12}}
              onPress={() => {
                setPressed('out')
                navigation.navigate('Logout')
              }}
            />
          </List.Section>
        </Animated.View>

      </DrawerContentScrollView>
    </View>
  );
};

function BarHeaderIcon(props) {
  return (
    <View style={{ flexDirection: 'row' }} >
      <FontAwesome name={props.icon} color={theme.colors.primary} size={20} style={{paddingRight:10}}></FontAwesome>
      <Text style={{color:theme.colors.primary, fontSize:14 }}>
        {props.title}
      </Text>
    </View>
  );
}

function HeaderRight() {

  const context = useContext(LoginContext)  
  useEffect(() => {}, [context]);

  const ndate = new Date();
  const hours = ndate.getHours();
  var selamat;
  
  if (hours>=1 && hours<11){
    selamat = 'Selamat Pagi';
  } else if (hours>=11 && hours<15) {
    selamat = 'Selamat Siang';
  } else if (hours>=15 && hours<19) {
    selamat = 'Selamat Sore';
  } else {
    selamat = 'Selamat Malam';
  }

  return (<View 
    style={{
      // backgroundColor: 'rgba(204, 204, 255, 0.9)',
      width: 240,
      padding: 10,
      marginRight: 10,
      borderRadius: 10,
      textAlign: "right",
      // opacity: 0.4,
    }}>
      {/* <TouchableOpacity onPress={() => {
        setCloseChip(true)
      }}>
        <Text style={{textAlign: "right", marginTop:-20}}><FontAwesome name={'close'}></FontAwesome></Text>
      </TouchableOpacity> */}
      <Text>{selamat}</Text>
      <View style={styles.mncontainer}>
        <View style={{width: '96%'}}>
          <Text style={{fontSize:14, fontWeight:'bold'}}>{context.fullname}</Text>
        </View>
      </View>
      <Text>Terakhir Masuk : {context.last_login}</Text>
    </View>
  )
}

function HeaderCenter() {

  const context = useContext(LoginContext)  
  useEffect(() => {}, [context]);
  const windowWidth = Dimensions.get('window').width;
  return (<View style={{textAlign: "center", alignItems: 'flex-end'}}><Text style={{fontSize:18, fontWeight:'bold', textAlign: "center"}}>{context.bankname}</Text></View>)
}

export default function DrawerStack({navigation}) {
  const [listMenu, setListMenu] = useState();  
  const [dashMenu, setDashMenu] = useState();  
  const [visible, setVisible] = useState();
  const [errorMessage, setErrorMessage] = useState();
  
  useEffect(() => {
    
    try {
      axios.get( process.env.REACT_APP_URL_API + "webarb/main/menu/SP",  {params:{code_apps:'SP'}}, {timeout: 120000})
      .then(res => {

        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);

          if(res.data.error.status=='401' || res.data.error.status=='500' || res.data.error.onOK){
            navigation.navigate('Login')
          }

        }else{

          var mrg = []
          var mrgDashboard = []
          for (let i = 0; i < res.data.data.length; i++) {
            if(listMenu) mrg[listMenu.length+i] = res.data.data[i]
            else mrg[i] = res.data.data[i]
            
            if(res.data.data[i].tabtype==='W'){
              if(dashMenu) mrgDashboard[dashMenu.length+i] = res.data.data[i]
              else mrgDashboard[i] = res.data.data[i]
            }
          }
          
          var serverMenu = listMenu? Object.assign( listMenu, mrg): mrg
          
          setDashMenu(dashMenu? Object.assign( dashMenu, mrgDashboard): mrgDashboard)
          setListMenu(serverMenu)
          // setListMenu(serverMenu && serverMenu.sort(function(a, b) {
          //   return b.seq - a.seq;
          // }))

        }

      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
    
  }, []);

  return (  
    <GlobalState>
      <Drawer.Navigator
        initialRouteName="Index"
        useLegacyImplementation={true}
        // screenOptions={{headerShown: false}}
        drawerContent={props => <CustomDrawer navigation={navigation} listMenu={dashMenu} />}
      >
        <Drawer.Screen
          name="Index"
          component={NonBottomStack}
          options={{
            // headerShown: false,
            // title: (<View style={{width:32}}>
            //     <TouchableOpacity onPress={() => {
            //       navigation.navigate('Inbox')
            //     }}> 
            //       <Badge size={17} style={{ position: 'absolute', top: -2, }}>3</Badge>
            //       <FontAwesome style={{textAlign: "left"}} name={'envelope-o'} size={20}></FontAwesome> 
            //     </TouchableOpacity>
            //   </View>
            // ),
            headerTitle : () => (
              <View style={{ flexDirection: 'row'}} >
                {/* <View style={{width:32}}>
                  <TouchableOpacity onPress={() => {
                    navigation.navigate('Inbox')
                  }}> 
                    <Badge size={17} style={{ position: 'absolute'}}>3</Badge>
                    <FontAwesome style={{textAlign: "left"}} name={'envelope-o'} size={20}></FontAwesome> 
                  </TouchableOpacity>
                </View> */}
                {window.innerWidth > 500?<HeaderCenter />:""}
              </View>
            ),
            headerRight: () => <HeaderRight />,
            drawerIcon: () => <FontAwesome name="home" color={theme.colors.primary} size={20} />,
            headerTitleAlign: 'center',
            headerStyle: {
                backgroundColor: theme.colors.secondary,
            },  
          }}
        />
        {
          listMenu && listMenu.map(function(o, i){
            if(!o.sub){
              return <Drawer.Screen
                  name={o.module}
                  component={routes(o.module)}
                  key={o.module}
                  options={{
                    title: o.caption,
                    headerStyle: {
                        backgroundColor: theme.colors.secondary
                    },  
                  }}
                />
            }
          })
        }
        <Drawer.Screen
          name="Account"
          component={AccountScreen}
          options={{
            title: 'Akun',
            headerTitle : () => <BarHeaderIcon icon="user" title="Akun" />,
            drawerIcon: () => (
              <FontAwesome name="user" color={theme.colors.primary} size={20} />
            ),
            headerStyle: {
                backgroundColor: theme.colors.secondary
            },  
          }}
        />
        <Drawer.Screen
          name="Session"
          component={SessionScreen}
          options={{
            title: 'Sesi',
            headerTitle : () => <BarHeaderIcon icon="clock-o" title="Sesi" />,
            drawerIcon: () => (
              <FontAwesome name="clock-o" color={theme.colors.primary} size={20} />
            ),
            headerStyle: {
                backgroundColor: theme.colors.secondary
            },  
          }}
        />
        <Drawer.Screen
          name="Logout"
          component={LogoutScreen}
          options={{
            title: 'Keluar',
            headerTitle : () => <BarHeaderIcon icon="sign-out" title="Keluar" />,
            drawerIcon: () => (
              <FontAwesome name="sign-out" color={theme.colors.primary} size={20} />
            ),
            headerStyle: {
                backgroundColor: theme.colors.secondary
            },
          }}
        />
        <Drawer.Screen
          name="Inbox"
          component={InboxScreen}
          options={{
            title: 'Pesan',
            // tabBarBadge: 3,
            drawerItemStyle: { height: 0 },
            headerTitle : () => <BarHeaderIcon icon="envelope-o" title="Pesan" />,
            drawerIcon: () => (
              <FontAwesome name="envelope" color={theme.colors.primary} size={20} />
            ),
            headerStyle: {
                backgroundColor: theme.colors.secondary
            },  
          }}
        />
      </Drawer.Navigator>
    </GlobalState>
  );
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 10,
  },
  mncontainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start' // if you want to fill rows left to right
  },
  itemContainer: {
    borderRadius: 15,
    padding: 10,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemName: {
    paddingTop: 6,
    fontSize: 14,
    color: '#fff',
    fontWeight: '600',
  },
});