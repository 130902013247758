import { View, ActivityIndicator, StyleSheet } from 'react-native'
import { Portal } from 'react-native-paper'

export default function Loader({ size, ...props }) {
  return (
    <Portal>
      <View style={[styles.container, styles.horizontal]}>
        <ActivityIndicator size={size} {...props} />
      </View>
    </Portal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
})
