import React, { useState } from 'react'
import { Image, TouchableOpacity, StyleSheet, View, KeyboardAvoidingView } from 'react-native'
import { Text, TextInput as Input } from 'react-native-paper'
import axios from 'axios'
import { wrapper } from 'axios-cookiejar-support'
import { CookieJar } from 'tough-cookie'
import AsyncStorage from '@react-native-async-storage/async-storage'

import {
  Background,
  Logo,
  Header,
  ButtonCostum,
  TextInput,
  DialogCostum
} from '../components'

import { theme } from '../core/theme'
import { nameValidator } from '../helpers/nameValidator'
import { passwordValidator } from '../helpers/passwordValidator'

export default function LoginScreen({ navigation }) {
  const [isPasswordSecure1, setIsPasswordSecure1] = useState(true);
  const [visible, setVisible] = useState(false)
  const [direct, setDirect] = useState(false)
  const [name, setName] = useState({ value: '', error: '' })
  const [password, setPassword] = useState({ value: '', error: '' })
  const [errorMessage, setErrorMessage] = useState(null)

  const hideDialog = () => {
    setVisible(false)
    if(direct) navigation.navigate('New Password', { password })
  }

  const onLoginPressed = async () => {
    const nameError = nameValidator(name.value)
    const passwordError = passwordValidator(password.value)
    if (nameError || passwordError) {
      setName({ ...name, error: nameError })
      setPassword({ ...password, error: passwordError })
      return
    }

    const code = 'SP';

    const postData = {
      // username: name.value,
      // password: password.value,
      code_apps: code,
    };
    
    // navigation.reset({
    //   index: 0,
    //   routes: [{ name: 'HomeScreen' }],
    // })
    
    try {
      const jar = new CookieJar();
      axios.defaults.headers.common['Authorization'] = btoa(JSON.stringify({
        "dbConnection": location.pathname.replace('/',''), 
        "code_apps": code,
        "username": name.value,
        "password": password.value
    }));
      axios.defaults.withCredentials = true;
      wrapper(axios);
      
      await axios.post( process.env.REACT_APP_URL_API + "webarb/login/auth",  postData, {
        timeout: 120000,
        jar
      })
      .then(res => {

        if (res.data.data){
          if(res.data.data.token) {
            axios.defaults.headers.common['Authorization'] = res.data.data.token;
            axios.defaults.jar = jar;
          }

          if(res.data.message && res.data.data.chgpwd){
            setErrorMessage(res.data.message);
            setVisible(true);
            setDirect(true);
            
          }else{
            
            axios.get( process.env.REACT_APP_URL_API + "SPRP0001/read/office")
            .then(resp => {              
              AsyncStorage.setItem('@signStorage', JSON.stringify(Object.assign(res.data.data, resp.data.data)));
              navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
              })
            })
            .catch(err => {
              console.log(err);
              setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
              setVisible(true);
            });

          }
        }else{
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }

      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });

    } catch (err) {
      console.log(err);
      setErrorMessage("Server Luring");
      setVisible(true);
    }
  }  

const handleKeyDown = (e) => {
    if(e.nativeEvent.key == "Enter"){
        onLoginPressed();
    }
}
        //<Logo />
  return (
    <Background>
      <KeyboardAvoidingView style={styles.container} behavior="padding">

        <Text style={styles.link}>____________________________________ {"\n\n"}</Text>
        <Header><Image source={require('../assets/imgs/logo/dashboard-logo.png')} style={styles.image} /></Header>
        <Text style={[styles.link, {color:'yellow'}]}>____________________________________</Text>
        <TextInput
          isFocus={true}
          label="Nama Pengguna"
          returnKeyType="next"
          value={name.value}
          onChangeText={(text) => setName({ value: text, error: '' })}
          error={!!name.error}
          errorText={name.error}
        />
        <TextInput
          label="Kata Sandi"
          returnKeyType="done"
          value={password.value}
          onChangeText={(text) => setPassword({ value: text, error: '' })}
          error={!!password.error}
          errorText={password.error}
          right={<Input.Icon icon={isPasswordSecure1 ? "eye-off" : "eye"} onPress={() => { isPasswordSecure1 ? setIsPasswordSecure1(false) : setIsPasswordSecure1(true) }}/>}
          secureTextEntry={isPasswordSecure1}
	   onKeyPress={handleKeyDown}
        />
        <View style={styles.forgotPassword}>
          <TouchableOpacity
            onPress={() => navigation.navigate('Forgot Password')}
          >
            <Text style={styles.link}>Lupa Kata Sandi?</Text>
          </TouchableOpacity>
        </View>
        <ButtonCostum mode="contained" onPress={onLoginPressed}>
          Masuk
        </ButtonCostum>
        <View style={styles.row}>
          <Text>Version 1.0.0</Text>
          {/* <Text>Don’t have an account? </Text>
          <TouchableOpacity onPress={() => navigation.navigate('Sign Up')}>
            <Text style={styles.link}>Sign up</Text>
          </TouchableOpacity> */}
        </View>

      </KeyboardAvoidingView>
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 12,
  },
  container: {
    flex: 1,
    paddingTop: 50,
    padding: 10,
    maxWidth: 340,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  image: {
    width: 200,
    height: 45,
  },
})
