import React from 'react'
import { ScrollView } from "react-native";
import ReactDOM from 'react-dom';
import { VictoryBar, VictoryLabel, VictoryLegend, VictoryGroup, VictoryChart, VictoryAxis, VictoryTheme, VictoryStack } from 'victory';
import Background from '../../components/Background'

export default function Home({ navigation }) {
  return (
    <Background>
      <ScrollView contentInsetAdjustmentBehavior="automatic" >
        Screen M-Info
      </ScrollView>
    </Background>
  )
}