import React, { useState, useEffect } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, SafeAreaView } from 'react-native'
import axios from 'axios'
import { Picker } from '@react-native-picker/picker'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import { Button, Divider, Dialog, Portal, Menu, Provider, TextInput as Input } from 'react-native-paper'
import DropDown from "react-native-paper-dropdown"

import { theme } from '../../core/theme'
import {
  TextInput,
} from '../../components'

export default function SPRP0011Params({...props}) {
  const date = new Date(props.home.oprdt)
  const [selected, setSelected] = useState()
  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [brnm, setBrnm] = useState()
  const [office, setOffice] = useState({ value: props.home.brncd, error: '' })
  const [year, setYear] = useState({ value: date.getFullYear(), error: '' })
  
  const [officeDropDown, setOfficeDropDown] = useState(false);
  const [officeList, setOfficeList] = useState();
  
  // const officeList = props.home.access && props.home.access.map(function(o, i){
  //   return { label: o.brncd+' - '+o.brnm, value: o.brncd };
  // })
  
  useEffect(() => {
    try {
      axios.get( process.env.REACT_APP_URL_API + props.progid +"/read",  {params:{
        method:"getaccessoffice",
      }}, {timeout: 120000})
      .then(res => {
        console.log(res);
  
        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{
          setOfficeList(res.data.data)
        }
  
      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
  }, []);
  
  const hideDialog = () => {
    setVisible(false)
  }

  const yearValidator = (v) => {
    if (!v) return "Tahun tidak boleh kosong."
    if (parseInt(v)<1000 || parseInt(v)>9999 || isNaN(parseInt(v))) return "Tahun tidak valid."
    return ''
  }

  const officeValidator = (v) => {
    if (!v) return "Kantor tidak boleh kosong."
    return ''
  }
  
  const onSubmit = async () => {
    const yearError = yearValidator(year.value)
    const officeError = officeValidator(office.value)

    setOffice({ ...office, error: officeError })
    setYear({ ...year, error: yearError })
    if (officeError || yearError) {
      return
    }
    
    var brnm
    officeList && officeList.map(function(o, i){
      if(officeList[i].brncd == office.value)
        brnm= o.brnm;
    })

    props.action({office, year, brnm});
    setVisible(false)
  }

  return <>
    <View>
      <View style={[styles.mncontainer, {padding:10}]}>
        <View style={styles.mncontainer} >
          <Text>Cetak : </Text>
          <Picker
            selectedValue={selected}
            onValueChange={(itemValue, itemIndex) =>
              props.export(itemValue)
            }>
            <Picker.Item label="- Pilihan -" value="" />
            <Picker.Item label="PDF" value="PDF" />
            <Picker.Item label="EXCEL" value="EXCEL" />
          </Picker>
        </View>
        <View style={{flexDirection: 'row'}} >
          <TouchableOpacity
            onPress={() => setVisible(true)}
          >
            <FontAwesome  name="edit" size={16}></FontAwesome>
          </TouchableOpacity>
        </View>
      </View>
    </View>
    
    <Portal>
      <Dialog visible={visible} onDismiss={hideDialog} style={{ alignSelf: "center" }} >
        <Dialog.Title><FontAwesome name="edit" size={16}></FontAwesome> Ubah Parameter</Dialog.Title>
	 <Divider />
        <Dialog.Content>
          
          <SafeAreaView>

            <View style={styles.dropDown}>
              <DropDown
                label={"Kantor"}
                mode={"outlined"}
                value={office.value}
                setValue={(text) => setOffice({ value: text, error: '' })}
                list={
                  officeList && officeList.map(function(o, i){
                    return { label: o.brncd+' - '+o.brnm, value: o.brncd };
                  })
                }
                error={!!office.error}
                errorText={office.error}
                visible={officeDropDown}
                showDropDown={() => setOfficeDropDown(true)}
                onDismiss={() => setOfficeDropDown(false)}
                inputProps={{
                  right: <Input.Icon name={officeDropDown?"menu-up":"menu-down"} />,
                }}
              />
              {office.error ? <Text style={styles.error}>{office.error}</Text> : null}
            </View>

            <TextInput
              label="Tahun"
              returnKeyType="next"
              value={year.value}
              onChangeText={(text) => setYear({ value: text.replace(/[^0-9]/g, ''), error: '' })}
              // error={!!year.error}
              errorText={year.error}
            />

          </SafeAreaView>
        </Dialog.Content>
	 <Divider />
        <Dialog.Actions>
          <Button onPress={() => setVisible(false)} >Batal</Button>
          <Button onPress={onSubmit}>Tampilkan</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  </>
}

const styles = StyleSheet.create({
  mncontainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start' // if you want to fill rows left to right
  },
  description: {
    fontSize: 13,
    color: theme.colors.secondary,
    paddingTop: 8,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
  dropDown: {
    marginVertical: 12,
    // flex: 1,
    // left:-100 , position: 'absolute',
  },
})
