import React, { useEffect, useContext } from 'react'
import { Avatar, List, Divider, Title, Card } from 'react-native-paper'
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'

import Background from '../../components/Background'
import { LoginContext } from '../../contexts/GlobalState'
import { theme } from '../../core/theme'

export default function HomeScreen({ route, navigation }) {   
  const context = useContext(LoginContext)  
  useEffect(() => {}, [context]);
  // Notify
  const items = [
    { name: 'Semua Persetujuan', screen: 'apprNotifyAll', icon: 'file-document-outline', color:'#103996', bgcolor:'#D1E9FC' },
    { name: 'Butuh Persetujuan', screen: 'apprNotifyNeed', icon: 'file-question-outline', color:'#B78103', bgcolor:'#FFF7CD' },
    { name: 'Disetujui', screen: 'apprNotifyAgree', icon: 'file-check-outline', color:'#229A16', bgcolor:'#E9FCD4' },
    { name: 'Ditolak', screen: 'apprNotifyReject', icon: 'file-remove-outline', color:'#B72136', bgcolor:'#FFE7D9' },
  ];

  return (
    <Background>
      <ScrollView contentInsetAdjustmentBehavior="automatic" >
        <KeyboardAvoidingView style={styles.container} behavior="padding">
          <FlatGrid
            itemDimension={140}
            data={items}
            style={styles.gridView}
            // staticDimension={300}
            // fixed
            spacing={10}
            renderItem={({ item }) => (
              <TouchableOpacity style={[styles.itemContainer, { backgroundColor: item.bgcolor }]} onPress={() => {
                alert(`go to ${item.screen}`)
              }}>
                <MaterialCommunityIcons name={item.icon} size={32} color={item.color}></MaterialCommunityIcons>
                <Text style={{fontWeight: '600', fontSize: 14, paddingTop: 6, color:item.color}}>10</Text>
                <Text style={{fontWeight: '600', fontSize: 14, paddingTop: 6, color:item.color}}>{item.name}</Text>
              </TouchableOpacity>
            )}
          />
          
          <Card>
            <Card.Content>
              <Title>Butuh Persetujaun</Title>
              <View>
                <Divider />
                <List.Item
                  title="Tarik Tunai"
                  description='No Slip: 0000666 Pengirim: Saka'
                  left={() => <MaterialCommunityIcons
                    style={[styles.listHeaderIcon, { backgroundColor: theme.colors.secondary }]}
                    size={24}
                    color={'#B78103'}
                    name="file-question-outline"></MaterialCommunityIcons>}
                  right={() => <Text style={{ alignSelf: "flex-end" }}>
                      <MaterialCommunityIcons size={12} name="clock-time-three-outline"></MaterialCommunityIcons> 11.00
                    </Text>}
                />
                <Divider />
                <List.Item
                  title="Setor Tunai"
                  description='No Slip: 0000667 Pengirim: Isme'
                  left={() => <MaterialCommunityIcons
                    style={[styles.listHeaderIcon, { backgroundColor: theme.colors.secondary }]}
                    size={24}
                    color={'#B78103'}
                    name="file-question-outline"></MaterialCommunityIcons>}
                  right={() => <Text style={{ alignSelf: "flex-end" }}>
                      <MaterialCommunityIcons size={12} name="clock-time-three-outline"></MaterialCommunityIcons> 11.00
                    </Text>}
                />
              </View>
            </Card.Content>
          </Card>

        </KeyboardAvoidingView>
      </ScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 10,
  },
  container: {
    flex: 1,
    padding: 10,
    maxWidth: 720,
    width: '100%',
    alignSelf: 'center',
  },
  itemContainer: {
    borderRadius: 15,
    padding: 10,
    height: 100,
    alignItems: 'center',
  },
  listHeaderIcon: {
    borderRadius: 45,
    padding: 10,
    height: 45,
    width: 45,
    alignItems: 'center',
  },
});
