import React from 'react'
import { View, Text } from 'react-native'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import {
  HomeScreen,
} from '../screens'

import {
  HomeDashboard,
} from '../screens/mdashboard'

import {
  HomeApproval,
} from '../screens/mapproval'

import {
  HomeCollection,
} from '../screens/mcollection'

import {
  HomeInfo,
} from '../screens/minfo'

import { Stack } from './Container'
import { theme } from '../core/theme'

export default function NonBottomStack({ navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Home" component={HomeScreen} options={{headerShown: false}} />
      <Stack.Screen name="Dashboard" component={HomeDashboard} options={{
          headerStyle: {
              backgroundColor: theme.colors.secondary
          },  
          headerTitle : () => <BarHeaderIcon icon="dashboard" title="Dashboard" />
        }}
      />
      <Stack.Screen name="M-Approval" component={HomeApproval} options={{
          headerStyle: {
              backgroundColor: theme.colors.secondary
          },  
          headerTitle : () => <BarHeaderIcon icon="check-square-o" title="M-Approval" />
        }}
      />
      <Stack.Screen name="M-Collection" component={HomeCollection} options={{
          headerStyle: {
              backgroundColor: theme.colors.secondary
          },  
          headerTitle : () => <BarHeaderIcon icon="diamond" title="M-Collection" />
        }}
      />
      <Stack.Screen name="M-Info" component={HomeInfo} options={{
          headerStyle: {
              backgroundColor: theme.colors.secondary
          },  
          headerTitle : () => <BarHeaderIcon icon="file-text-o" title="M-Info" />
        }}
      />
      <Stack.Screen name="Logout" component={HomeInfo} options={{
          headerStyle: {
              backgroundColor: theme.colors.secondary
          },  
          headerTitle : () => <BarHeaderIcon icon="file-text-o" title="M-Info" />
        }}
      />
    </Stack.Navigator>
  );
}

function BarHeaderIcon(props) {
  return (
    <View style={{ flexDirection: 'row' }} >
      <FontAwesome name={props.icon} color={theme.colors.primary} size={20} style={{paddingRight:10}}></FontAwesome>
      <Text style={{color:theme.colors.primary, fontSize:14 }}>
        {props.title}
      </Text>
    </View>
  );
}