import React, { useState } from 'react'
import { StyleSheet, KeyboardAvoidingView } from 'react-native'
import { TextInput as Input } from 'react-native-paper'
import axios from 'axios'

import FontAwesome from '@expo/vector-icons/FontAwesome'
import {
  Background,
  Logo,
  Header,
  ButtonCostum,
  TextInput,
  DialogCostum
} from '../components'

import { passwordValidator } from '../helpers/passwordValidator'

export default function ResetPasswordScreen({ route, navigation }) {
  const [visible, setVisible] = useState(false)
  const [backlogin, setBackLogin] = useState(false)
  const hideDialog = () => {
    setVisible(false)
    if(backlogin) navigation.goBack()
  }
  const [errorMessage, setErrorMessage] = useState(null)
  const [password, setPassword] = useState({ value: '', error: '' })
  const [retype, setRetype] = useState({ value: '', error: '' })
  const [isPasswordSecure1, setIsPasswordSecure1] = useState(true);
  const [isPasswordSecure2, setIsPasswordSecure2] = useState(true);

  const resetPassword = async () => {
    const passwordError = passwordValidator(password.value)
    const retypeError = passwordValidator(retype.value)
    if (passwordError || retypeError) {
      setPassword({ ...password, error: passwordError })
      setRetype({ ...retype, error: retypeError })
      return
    }

    if(route.params.password.value===retype.value){
      setErrorMessage("Kata sandi baru telah digunakan sebelumnya")
      setVisible(true)
      return
    }

    if(password.value !== retype.value){
      setErrorMessage("Kata sandi baru tidak diketik ulang dengan benar")
      setVisible(true)
      return
    }

    const postData = {
      newpassword: retype.value,
      oldpassword: route.params.password.value,
      code_apps: 'SP',
    };
    
    try {
      await axios.post( process.env.REACT_APP_URL_API + "webarb/login/newPassword",  postData, {
        timeout: 120000,
        // withCredentials: true,
      })
      .then(res => {
        console.log(res);

        if (res.data.data){
            setErrorMessage(res.data.data.message);
            setVisible(true);
            setBackLogin(true);
        }else{
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }

      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage("Server Luring");
      setVisible(true);
    }
  }

  return (
    <Background>
      <KeyboardAvoidingView style={styles.container} behavior="padding">
        <Logo />
        <Header>Reset Kata Sandi</Header>
        <TextInput
          secureTextEntry={isPasswordSecure1}
          isFocus={true}
          label="Kata Sandi"
          returnKeyType="done"
          value={password.value}
          onChangeText={(text) => setPassword({ value: text, error: '' })}
          error={!!password.error}
          errorText={password.error}
          right={<Input.Icon icon={isPasswordSecure1 ? "eye-off" : "eye"} onPress={() => { isPasswordSecure1 ? setIsPasswordSecure1(false) : setIsPasswordSecure1(true) }}/>}
        />
        <TextInput
          secureTextEntry={isPasswordSecure2}
          label="Ulangi Kata Sandi"
          returnKeyType="done"
          value={retype.value}
          onChangeText={(text) => setRetype({ value: text, error: '' })}
          error={!!retype.error}
          errorText={retype.error}
          right={<Input.Icon icon={isPasswordSecure2 ? "eye-off" : "eye"} onPress={() => { isPasswordSecure2 ? setIsPasswordSecure2(false) : setIsPasswordSecure2(true) }}/>}
        />
        <ButtonCostum 
          mode="contained"
          onPress={resetPassword}
          style={{ marginTop: 16 }}
        >
          Kirim
        </ButtonCostum>
      </KeyboardAvoidingView>
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 50,
    padding: 10,
    maxWidth: 340,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
