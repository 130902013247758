import React, { useState, useEffect, useRef } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, SafeAreaView } from 'react-native'
import axios from 'axios'

import DatePicker from "react-datepicker"
// import DatePicker from 'react-native-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { Picker } from '@react-native-picker/picker'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import { Button, Divider, Dialog, Portal, Menu, Provider, TextInput as Input } from 'react-native-paper'
import DropDown from "react-native-paper-dropdown"

import { theme } from '../../core/theme'   
import {
  TextInput,
} from '../../components'

export default function TglParams({...props}) {
  const hdate = new Date(props.home.oprdt)
  const [currendt, setCurrendt] = useState();

  const [selected, setSelected] = useState()
  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [brnm, setBrnm] = useState()
  const [office, setOffice] = useState({ value: props.home.brncd, error: '' })
  const [getdate, setDate] = useState({ value: props.home.oprdt, error: '' })
  
  const [officeDropDown, setOfficeDropDown] = useState(false);
  const [officeList, setOfficeList] = useState();
  
  const datepickerRef = useRef(null);

  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }
  
  useEffect(() => {
    try {
      axios.get( process.env.REACT_APP_URL_API + props.progid +"/read",  {params:{
        method:"getaccessoffice",
      }}, {timeout: 120000})
      .then(res => {
        setCurrendt(new Date(props.home.oprdt))

        // console.log(['baru',props.home.oprdt]);
        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{
          setOfficeList(res.data.data)
        }
  
      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
  }, []);
  
  const hideDialog = () => {
    setVisible(false)
  }

  const dateValidator = (v) => {
    if (!v && v!==0) return "Tanggal tidak boleh kosong."
    return ''
  }

  const officeValidator = (v) => {
    if (!v) return "Kantor tidak boleh kosong."
    return ''
  }
  
  const onSubmit = async () => {
    const officeError = officeValidator(office.value)
    // const dateError = dateValidator(getdate.value)

    setOffice({ ...office, error: officeError })
    // setDate({ ...getdate, error: dateError }) || dateError
    if (officeError) {
      return
    }

    var brnm
    officeList && officeList.map(function(o, i){
      if(officeList[i].brncd == office.value)
        brnm= o.brnm;
    })
    props.action({office, getdate, brnm});
    
    setVisible(false)
  }
  return <>
    <View>
      <View style={[styles.mncontainer, {padding:10}]}>
        <View style={styles.mncontainer} >
          <Text>Cetak : </Text>
          <Picker
            selectedValue={selected}
            onValueChange={(itemValue, itemIndex) =>
              props.export(itemValue)
            }>
            <Picker.Item label="- Pilihan -" value="" />
            <Picker.Item label="PDF" value="PDF" />
            <Picker.Item label="EXCEL" value="EXCEL" />
          </Picker>
        </View>
        <View style={{flexDirection: 'row'}} >
          <TouchableOpacity
            onPress={() => setVisible(true)}
          >
            <FontAwesome  name="edit" size={16}></FontAwesome>
          </TouchableOpacity>
        </View>
      </View>
    </View>
     
    <Portal>
      <Dialog visible={visible} onDismiss={hideDialog} style={{ alignSelf: "center" }} >
        <Dialog.Title><FontAwesome name="edit" size={16}></FontAwesome> Ubah Parameter</Dialog.Title>
	      <Divider />
        <Dialog.Content  style={{ height: 400, width:350}}>
          <SafeAreaView>
            <View style={{flexDirection: 'row', marginVertical: 12}} >
              <Text style={{fontWeight:'bold', width:92}}>Tanggal :</Text>
                <DatePicker
                  style={styles.datePickerStyle}
                  mode={"outlined"}
                  dateFormat="dd/MM/yyyy"
                  selected={currendt}
                  onChange={(date) => {
                    setCurrendt(date)
                    setDate({ value: date, error: '' })
                  }}
                  ref={datepickerRef} // attach the ref
                />
              <FontAwesome onPress={() => handleClickDatepickerIcon()} name="calendar" size={18} style={{position:'relative'}}></FontAwesome>
            </View>
          </SafeAreaView>
        </Dialog.Content>
	     <Divider />
        <Dialog.Actions>
          <Button onPress={() => setVisible(false)} >Batal</Button>
          <Button onPress={onSubmit}>Tampilkan</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  </>
}

const styles = StyleSheet.create({
  mncontainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start' // if you want to fill rows left to right
  },
  description: {
    fontSize: 13,
    color: theme.colors.secondary,
    paddingTop: 8,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
  dropDown: {
    marginVertical: 12,
    // flex: 1,
    // left:-100 , position: 'absolute',
  },
  container: {
    flex: 1,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    padding: 20,
  },
  datePickerStyle: {
    width: 200,
    marginTop: 20,
  },
})
