import React, { useState, useRef, useEffect, useContext } from 'react'
import { Card, DataTable } from 'react-native-paper'
import { View, Text, ScrollView, StyleSheet, TouchableOpacity} from "react-native";
import axios from 'axios'
import Background from '../../components/Background'
import TglParams from './TglParams'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";  
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.min.js"; 
import "datatables.net-buttons/js/buttons.html5.min.js";
// import "jszip/dist/jszip.min.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts"; 
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import $ from "jquery";

const jzip = require( 'jszip/dist/jszip.js');
window.JSZip = jzip;

import {
  DialogCostum,
  Loader,
} from '../../components'

import { LoginContext } from '../../contexts/GlobalState'
import { monthList } from '../../utils/monthList'
import { currencyFormat } from '../../utils/currency';

export default function SPRP0009({ route, navigation }){
  $.DataTable = require('datatables.net')
  const tableRef = useRef()
  const tableName = "table"+route.name

  const homeprm = navigation.getState();
  const home = homeprm.routes[0].state.routes[0].params;
  let table;

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [dataGrid, setDataGrid] = useState()
  const [office, setOffice] = useState()
  const [month, setMonth] = useState()
  const [getdate, setDate] = useState()
  const [reportDate, setReportDate] = useState()
  const [spin, setSpin] = useState()

  const hideDialog = () => {
    setVisible(false)
  }

  const date = new Date(home.oprdt)

  const exportTo = (exp) =>{    
    if(exp==='EXCEL' || exp==='PDF')
      axios.get(process.env.REACT_APP_URL_API + "SPRP0009/read", {
        params: {
          method: exp==='PDF'?"getpdf":"getxls",
          getdate: date,
        }
      })
      .then((res) => {
        if(res.data?.data?.path) window.location.href = process.env.REACT_APP_URL_API + res.data.data.path;
      });
  }


  const login = useContext(LoginContext)  
  useEffect(() => {
    // setTimeout( function () {
    //   $(`#${tableName}`).DataTable().columns.adjust();
    // }, 500)
  }, [login]);

  useEffect(() => {
    setOffice(home.brncd+' - '+home.brnm);
    const months = monthList(home.oprdt);
    setMonth(months.month.label)
    postSubmit({
      brnm:home.brnm,
      office:{value: home.brncd},
      getdate:{value: date}
    })
  }, []);

  const postSubmit = (v) => { 
    setSpin(true)
    try {
          const pdate = v.getdate.value
          const bln = pdate.getMonth()+1
          const sendDate = pdate.getDate()+"-"+bln+"-"+pdate.getFullYear(); 
          setReportDate(sendDate)
          
          const title = 'LAPORAN ATMR RINCI'+'\n'+ login.bankname+'\n'+ office+'\n'+sendDate

          table = $(`#${tableName}`).DataTable(
            {
              "ajax": {
                 "url": process.env.REACT_APP_URL_API + "SPRP0009/read",
                 "data": { 
                    method:"getreport",
                    office:v.office.value,
                    getdate:pdate.toISOString().slice(0, 10)
                 },
                 "dataType": 'json',
                 "dataSrc": function ( json ) {
                    setSpin(false)
                    json.draw = json.data.draw
                    json.recordsFiltered = json.data.recordsFiltered
                    json.recordsTotal = json.data.recordsTotal
                    json.data = json.data.columns
                    json.reportdate = json.data.reportdate
                    return json.data;
                 },
                 "type": "GET",
                 "beforeSend": function(xhr){xhr.setRequestHeader("Authorization", login.token) 
          									 setSpin(true)}
              },
              processing: true,
              serverSide: true,
              scrollY:"360px",
              scrollX:true,
              scrollCollapse: true,
              fixedHeader: true,
              dom: "lBfrtip",
              columnDefs: [
            { targets: [1,2,3,5], className: 'dt-body-right' },
            { targets: [4], className: 'dt-body-center' },
            { targets: [1,2,3,5], className: 'dt-head-right' }
          ],
          columns: [
            { title: "KOMPONEN"},
            { title: "NOMINAL"},
            { title: "PPAP KHUSUS"},
            { title: "NOMINAL SETELAH DIKURANGI PPAP"},
            { title: "BOBOT RISIKO"},
            { title: "ATMR"}
          ],
          oLanguage:{
            sSearch:"<span>Pencarian </span> _INPUT_",
            sLengthMenu:"Tampil _MENU_",
            sZeroRecords:"Tidak ada data untuk ditampilkan.",
            sInfo:"Total _TOTAL_ baris (_START_ - _END_)",
            sInfoFiltered: "", 
            sInfoEmpty: "Menampilkan 0 baris",
            oPaginate:{
              sNext:">",
              sPrevious:"<"
            }
          },
          searching:{
            regex:1
          },
          ordering: false,
          lengthMenu: [ [10, 25, 50, 100], [10, 25, 50, 100] ],
          destroy: true,  // I think some clean up is happening here
        }
      )
      $('.dt-buttons').hide();
    } catch (err) {
      setSpin(false)
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
  }

  return (
    <Background isVisibleBg={true}>
      <TglParams home={home} export={exportTo} action={postSubmit} progid={route.name} />
      <Card style={{backgroundColor: 'rgba(204, 204, 255, 0.2)'}}>
      <Card.Content>
          <View style={{alignItems:'center', padding:5}}>
            <Text style={{fontSize:14, fontWeight:'bold'}}>LAPORAN ATMR RINCI</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{login.bankname}</Text>
            <Text style={{fontSize:14, fontWeight:'bold', textTransform: 'uppercase'}}>{office}</Text>
            <Text style={{fontSize:14, fontWeight:'bold', textTransform: 'uppercase'}}>{reportDate}</Text>
          </View>

          <table
            style={{
              fontSize: '14px',
              fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif"
            }}
            className="display"
            width="100%"
            id={tableName}
            ref={tableRef}
          >
          </table>
        </Card.Content>
      </Card>
      { spin && <Loader size="large" animating={spin} />}
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    width: '100%',
  },
  row:{
    width: '60%'
  },
  bold_row:{
    fontWeight:'bold',
    // backgroundColor: '#F88379'
  }
});