import React, { useEffect, useContext } from 'react'
import {
  Text,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native'
import { FlatGrid } from 'react-native-super-grid'

import Background from '../../components/Background'
import { LoginContext } from '../../contexts/GlobalState'
import { theme } from '../../core/theme'

export default function HomeScreen({ route, navigation }) {   
  const context = useContext(LoginContext)  
  useEffect(() => {}, [context]);
  // Notify
  const items = [
    { name: 'Setoran', screen: 'collSetor', icon: 'setor64.png', color:'#fff', bgcolor:theme.colors.secondary },
    { name: 'Penarikan', screen: 'collTarik', icon: 'tarik64.png', color:'#fff', bgcolor:theme.colors.secondary },
    { name: 'Cetak Ulang', screen: 'collCetak', icon: 'invoice64.png', color:'#fff', bgcolor:theme.colors.secondary },
    { name: 'Info Saldo', screen: 'collSaldo', icon: 'saldo64.png', color:'#fff', bgcolor:theme.colors.secondary },
    { name: 'Info Transaksi', screen: 'collTransaksi', icon: 'book64.png', color:'#fff', bgcolor:theme.colors.secondary },
    { name: 'Rencana Pelunasan', screen: 'collRencana', icon: 'repay64.png', color:'#fff', bgcolor:theme.colors.secondary },
    { name: 'Denominasi', screen: 'collDenom', icon: 'cashier64.png', color:'#fff', bgcolor:theme.colors.secondary },
  ];

  return (
    <Background>
      <ScrollView contentInsetAdjustmentBehavior="automatic" >
        <KeyboardAvoidingView style={styles.container} behavior="padding">
          <FlatGrid
            itemDimension={80}
            data={items}
            style={styles.gridView}
            // staticDimension={300}
            // fixed
            spacing={10}
            renderItem={({ item }) => (
              <TouchableOpacity style={{alignItems: 'center'}} onPress={() => {
                alert(`go to ${item.screen}`)
              }}>
                <Image style={[styles.itemContainer, { backgroundColor: item.bgcolor }]} source={require(`../../assets/imgs/mcollection/${item.icon}`)} />
                <Text style={{fontWeight: '600', fontSize: 14, paddingTop: 6, color:'#000'}}>{item.name}</Text>
              </TouchableOpacity>
            )}
          />
        </KeyboardAvoidingView>
      </ScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 10,
  },
  container: {
    flex: 1,
    padding: 10,
    maxWidth: 420,
    width: '100%',
    alignSelf: 'center',
  },
  itemContainer: {
    borderRadius: 50,
    padding: 8,
    height: 50,
    width: 50,
    alignItems: 'center',
  },
});
