
export function monthList(date, list) {
    const d = new Date(date)
    
    const monthList = list?list:[
      { label: "JANUARI", value: 0 },
      { label: "FEBRUARI", value: 1 },
      { label: "MARET", value: 2 },
      { label: "APRIL", value: 3 },
      { label: "MEI", value: 4 },
      { label: "JUNI", value: 5 },
      { label: "JULI", value: 6 },
      { label: "AGUSTUS", value: 7 },
      { label: "SEPTEMBER", value: 8 },
      { label: "OKTOBER", value: 9 },
      { label: "NOVEMBER", value: 10 },
      { label: "DESEMBER", value: 11 },
    ];
        
    var month;
    for (let i = 0; i < monthList.length; i++) {
      if(monthList[i].value === d.getMonth()){
        month = monthList[i];
        break;
      }
    }
    
    return {
        key:d.getMonth(),
        list:monthList,
        month,
    }
}