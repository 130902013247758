import React, { useState, useRef, useEffect, useContext } from 'react'
import { Card, DataTable } from 'react-native-paper'
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import axios from 'axios'
import Background from '../../components/Background'
import ReportParams from '../../components/mdashboard/ReportParams'
// import FontAwesome from '@expo/vector-icons/FontAwesome'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
// import "datatables.net-buttons/js/buttons.colVis.js";
// import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
// import "datatables.net-buttons/js/buttons.print.js";
// import "jszip/dist/jszip.min.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import $ from "jquery";

const jzip = require( 'jszip/dist/jszip.js');
window.JSZip = jzip;

import {
  DialogCostum
} from '../../components'

import { LoginContext } from '../../contexts/GlobalState'
import { monthList } from '../../utils/monthList'
import { currencyFormat } from '../../utils/currency';

export default function SPRP0003({ route, navigation }) {
  $.DataTable = require('datatables.net')
  const tableRef = useRef()
  const tableName = "table"+route.name

  const homeprm = navigation.getState();
  const home = homeprm.routes[0].state.routes[0].params;
  let table;

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [dataGrid, setDataGrid] = useState()
  const [office, setOffice] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  const hideDialog = () => {
    setVisible(false)
  }

  const exportTo = (exp) =>{
    if(exp==='EXCEL'){
      $( `#${tableName}_wrapper` ).find('.buttons-excel').trigger('click');
    }
    if(exp==='PDF'){
      $( `#${tableName}_wrapper` ).find('.buttons-pdf').trigger('click');
    }
  }

  const login = useContext(LoginContext)  
  useEffect(() => {
    // setTimeout( function () {
    //   $(`#${tableName}`).DataTable().columns.adjust();
    // }, 500)
  }, [login]);
  useEffect(() => {  
    const date = new Date(home.oprdt)
    setYear(date.getFullYear())
  
    // for (let i = 0; i < home.access.length; i++) {
    //   if(home.access[i].brncd === home.brncd || home.access[i].brncd === office){
    //     setOffice(home.access[i].brncd+' - '+home.access[i].brnm);
    //     break;
    //   }
    // }
    
    setOffice(home.brncd+' - '+home.brnm);
    const months = monthList(home.oprdt);
    setMonth(months.month.label)
    postSubmit({
      brnm:home.brnm,
      office:{value: home.brncd},
      month:{value: date.getMonth()},
      year:{value: date.getFullYear()},
    })
  }, []);

  const title = 'LAPORAN LABA RUGI'+'\n'+ login.bankname+'\n'+ office+'\n'+ month+' '+year

  const postSubmit = (v) => {
    try {
      axios.post( process.env.REACT_APP_URL_API + "SPRP0003/read",  {
        method:"getreport",
        office:v.office.value,
        month:v.month.value,
        year:v.year.value,
      }, {timeout: 120000})
      .then(res => {
        console.log(res);

        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{
          setOffice(v.office.value+' - '+v.brnm)
          const months = monthList(new Date(v.year.value+"-"+(v.month.value+1)+"-01"))
          setMonth(months.month.label)
          setYear(v.year.value)
          setDataGrid(res.data.data)
          
          const title = 'LAPORAN LABA RUGI \n'+ login.bankname+' \n'+ v.office.value+' - '+v.brnm+' \n'+ months.month.label+' '+v.year.value
          
          table = $(`#${tableName}`).DataTable(
            {
              data: res.data.data,
              scrollY:"360px",
              scrollX:true,
              scrollCollapse: true,
              fixedHeader: true,
              processing: true,
              dom: "lBfrtip",
              buttons: [
                {
                  extend: "excelHtml5",
                  title: title,
                  // messageTop: ,
                },
                {
                  extend: 'pdfHtml5',
                  pageSize: 'LEGAL',
                  title: title,
                  // messageTop: ,
                  customize: function (doc) { 
                    doc.styles.title = {
                      fontSize: '12',
                      alignment: 'center'
                    }   
                    var rowCount = doc.content[1].table.body;
                    for (let i = 0; i < rowCount.length; i++) {
                      rowCount[i][2].alignment = 'right';
                    }
                  }
                },
              ],
              columnDefs: [
                // {
                //   target: 0,
                //   visible: false,
                // },
                { targets: [2], className: 'dt-body-right dt-head-right' },
                { targets: [1], className: "dt-body-center dt-head-center" }
              ],
              columns: [
                // { title: "NO"},
                { title: "POS LABA/RUGI"},
                { title: "SANDI POS"},
                { title: "JUMLAH (RIBUAN RP)"},
              ],
              oLanguage:{
                sSearch:"<span>Pencarian </span> _INPUT_",
                sLengthMenu:"Tampil _MENU_",
                sZeroRecords:"Tidak ada data untuk ditampilkan.",
                sInfo:"Total _TOTAL_ baris (_START_ - _END_)",
                sInfoFiltered: "(dari _MAX_ total baris)",
                sInfoEmpty: "Menampilkan 0 baris",
                oPaginate:{
                  sNext:">",
                  sPrevious:"<"
                }
              },
              scrollX:true,
              searching:{
                regex:1
              },
              ordering: false,
              pageLength: -1,
              lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "Semua"] ],
              destroy: true,  // I think some clean up is happening here
            }
          )
          
          table.columns.adjust().draw();
          $('.dt-buttons').hide();
          // Extra step to do extra clean-up.
          return function() {
              console.log("Table destroyed")
              table.destroy()
          }
        }

      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
  }

  return (
    <Background isVisibleBg={true}>
    
      <ReportParams home={home} export={exportTo} action={postSubmit} progid={route.name} />
        
      <Card style={{backgroundColor: 'rgba(204, 204, 255, 0.2)'}}>
        <Card.Content>

          <View style={{alignItems:'center', padding:5}}>
            <Text style={{fontSize:14, fontWeight:'bold'}}>LAPORAN LABA RUGI</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{login.bankname}</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{office}</Text>
            <Text style={{fontSize:14, fontWeight:'bold'}}>{`${month}`.toUpperCase()} {year}</Text>
          </View>

          <table
            style={{
              fontSize: '14px',
              fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif"
            }}
            className="display"
            width="100%"
            id={tableName}
            ref={ tableRef }
          >
          </table>

          {/* <DataTable style={{height:460}}> */}
          {/* <DataTable>
            <DataTable.Header>
              <DataTable.Title >POS LABA/RUGI</DataTable.Title>
              <DataTable.Title numeric>SANDI POS</DataTable.Title>
              <DataTable.Title numeric>JUMLAH (RIBUAN RP)</DataTable.Title>
            </DataTable.Header>
            <ScrollView>
            {
              dataGrid && dataGrid.map(function(o, i){
                return <DataTable.Row key={i}>
                    <DataTable.Cell ><Text style={{whiteSpace: "pre"}}>{o.comp}</Text></DataTable.Cell>
                    <DataTable.Cell numeric>{o.sandi}</DataTable.Cell>
                    <DataTable.Cell numeric>{currencyFormat(o.amount)}</DataTable.Cell>
                  </DataTable.Row>;
              })
            }
            </ScrollView>
          </DataTable> */}

        </Card.Content>
      </Card>
      
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}