import React, { useState } from 'react'
import { Dialog, Portal } from 'react-native-paper'
import {
  View,
  Text,
  ScrollView,
  TextInput,
  StyleSheet,
  KeyboardAvoidingView,
} from 'react-native'

import FontAwesome from '@expo/vector-icons/FontAwesome'

import Header from '../components/Header'
import Background from '../components/Background'
import { theme } from '../core/theme'
import Paragraph from '../components/Paragraph'

export default function InboxScreen({ navigation }) {

  return (
    <Background>
    <ScrollView contentInsetAdjustmentBehavior="automatic" >
      <KeyboardAvoidingView style={styles.container} behavior="padding">
        {/* <Header><FontAwesome name='envelope' size={24} color={theme.colors.primary}></FontAwesome> Pesan</Header> */}
        
      </KeyboardAvoidingView>
    </ScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 10,
  },
  container: {
    flex: 1,
    padding: 10,
    maxWidth: 720,
    width: '100%',
    alignSelf: 'center',
  },
});
