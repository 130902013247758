import React, { useState, useEffect } from 'react'
import { StyleSheet, SafeAreaView, View, Text, Image, KeyboardAvoidingView } from 'react-native'
import AppIntroSlider from 'react-native-app-intro-slider'
import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'

import Background from '../components/Background'
// import Logo from '../components/Logo'
// import Header from '../components/Header'
import ButtonCostum from '../components/ButtonCostum'
// import Paragraph from '../components/Paragraph'
import { theme } from '../core/theme'


export default function StartScreen({ navigation }) {
  const [showRealApp, setShowRealApp] = useState(false);

  const onDone = () => {
    setShowRealApp(true);
  };

  const onSkip = () => {
    setShowRealApp(true);
  };
  
  let isSignedIn = async () => {
    try {
      const data = await AsyncStorage.getItem('@signStorage');
  
      if (data !== null) {
          axios.defaults.headers.common['Authorization'] = JSON.parse(data).token;
          return true
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    isSignedIn().then(result =>{
      if(result) {
        navigation.navigate('Home')
      }
    })
  }, []);

  const RenderItem = ({ item }) => {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: item.backgroundColor,
          paddingBottom: 50,
        }}>
        <Image style={item.image.style} source={item.image.uri} />
        <Text style={styles.introTitleStyle}>{item.title}</Text>
        <Text style={styles.introTextStyle}>{item.text}</Text>
      </View>
    );
  };

  return (
  <Background>
    <KeyboardAvoidingView style={styles.container} behavior="padding">
        <AppIntroSlider
          data={slides}
          renderItem={RenderItem}
          onDone={onDone}
          showSkipButton={false}
          showDoneButton={false}
          onSkip={onSkip}
          style={{paddingTop:'40%'}}
        />
      {/* <Logo />
      <Header>Sinergi Apps</Header> */}
      <ButtonCostum
        mode="contained"
        onPress={() => navigation.navigate('Login')}
      >
        Login
      </ButtonCostum>
      <View style={styles.row}>
        <Text>Don’t have an account? </Text>
      </View>
        <ButtonCostum
          mode="outlined"
          onPress={() => navigation.navigate('Sign Up')}
        >
          Sign Up
        </ButtonCostum>
        <Text>By logging in or registering , you agree to our <Text style={styles.link}>Term of service</Text> and <Text style={styles.link}>Privacy policy</Text>.</Text>
      </KeyboardAvoidingView>
    </Background>
  )
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    resizeMode: 'cover', // or 'stretch'
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  container: {
    // flex: 1,
    // backgroundColor: '#fff',
    // alignItems: 'center',
    // padding: 10,
    // justifyContent: 'center',

    // flex: 1,
    padding: 10,
    maxWidth: 340,
    width: '100%',
    alignSelf: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
  },
  introTextStyle: {
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
    // padding: 50,
  },
  introTitleStyle: {
    fontSize: 18,
    padding: 10,
    color: '#000',
    textAlign: 'center',
    fontWeight: 'bold',
    // marginBottom: 16,
  },
})

const slides = [
  {
    key: '1',
    text: 'Core Banking System',
    title: 'Aplikasi Retail BPR',
    image: {
      uri: require('../assets/imgs/logo/arb.png'),
      style: {
        width: 150,
        height: 100,
        alignSelf: 'center',
      }
    },
  },
  {
    key: '2',
    text: 'Penunjang efektifitas fungsi penagihan kolektor',
    title: 'M-Collection',
    image: {
      uri: require('../assets/imgs/mcollection/logo.png'),
      style: {
        width: 100,
        height: 160,
        alignSelf: 'center',
      }
    },
  },
  {
    key: '3',
    title: 'M-Approval',
    text: 'Proses persetujuan transaksi/perubahan ARB',
    image: {
      uri: require('../assets/imgs/logo/sinergi-logo.png'),
      style: {
        width: 100,
        height: 100,
        alignSelf: 'center',
      }
    },
  },
  {
    key: '4',
    title: 'Dashboard',
    text: 'Manajemen informasi secara visual',
    image: {
      uri: require('../assets/imgs/logo/logodashboard.png'),
      style: {
        width: '100%',
        height: 100,
      }
    },
  },
  {
    key: '5',
    title: 'LOS',
    text: 'Loan Origination System',
    image: {
      uri: require('../assets/imgs/logo/l_o_s.gif'),
      style: {
        width: 100,
        height: 100,
        alignSelf: 'center',
      }
    },
  },
];