import React from 'react'
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import { theme } from '../core/theme'
import {
  AccountScreen,
  InboxScreen,
  LogoutScreen,
  SessionScreen,
} from '../screens'
import GlobalState from '../contexts/GlobalState'
import NonBottomStack from './NonBottomStack'

const Tab = createMaterialBottomTabNavigator()

export default function TabBottomStack({ navigation }) {
  return (
    <GlobalState>
      <Tab.Navigator
        initialRouteName="Index"
        activeColor={theme.colors.primary}
        inactiveColor={theme.colors.primary}
        labelStyle={{ fontSize: 12 }}
        barStyle={{ backgroundColor: theme.colors.secondary }}
      >
        <Tab.Screen
          name="Index"
          component={NonBottomStack}
          options={{
            tabBarLabel: 'Beranda',
            tabBarIcon: () => (
              <FontAwesome name="home" color={theme.colors.primary} size={26} />
            ),
          }}
        />
        <Tab.Screen
          name="Account"
          component={AccountScreen}
          options={{
            tabBarLabel: 'Akun',
            tabBarIcon: () => (
              <FontAwesome name="user" color={theme.colors.primary} size={26} />
            ),
          }}
        />
        <Tab.Screen
          name="Inbox"
          component={InboxScreen}
          options={{
            tabBarLabel: 'Pesan',
            tabBarBadge: 3,
            tabBarIcon: () => (
              <FontAwesome name="envelope" color={theme.colors.primary} size={26} />
            ),
          }}
        />
        <Tab.Screen
          name="Session"
          component={SessionScreen}
          options={{
            tabBarLabel: 'Sesi',
            tabBarIcon: () => (
              <FontAwesome name="clock-o" color={theme.colors.primary} size={26} />
            ),
          }}
        />
        <Tab.Screen
          name="Logout"
          component={LogoutScreen}
          options={({ navigation }) => ({
            tabBarIcon: () => (
              <FontAwesome name="sign-out" color={theme.colors.primary} size={26} />
            ),
            tabBarButton: (props) => (
              <TouchableOpacity onPress={() => {
                navigation.navigate('LogoutScreen')
              }}>
                <Text>Keluar</Text>
              </TouchableOpacity>
            ),
          })}
        />
      </Tab.Navigator>
    </GlobalState>
  );
}