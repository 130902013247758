require('dotenv/config')

module.exports = {
  "expo": {
    "name": "super-app-sinergi",
    "slug": "super-app-sinergi",
    "myfolder": process.env.REACT_APP_NAME_FOLDER,
    "version": "1.0.0",
    "orientation": "portrait",
    "icon": "./assets/icon.png",
    "userInterfaceStyle": "light",
    "splash": {
      "image": "./assets/splash.png",
      "resizeMode": "contain",
      "backgroundColor": "#ffffff"
    },
    "updates": {
      "fallbackToCacheTimeout": 0
    },
    "assetBundlePatterns": [
      "**/*"
    ],
    "platforms": [
      // "ios",
      "android",
      "web"
    ],
    "ios": {
      "supportsTablet": true
    },
    "android": {
      "package": "com.sinergi.superapps",
      "versionCode": 1,
      "adaptiveIcon": {
        "foregroundImage": "./assets/adaptive-icon.png",
        "backgroundColor": "#FFFFFF"
      }
    },
    "web": {
      "favicon": "./assets/favicon.png"
    },
    "extra": {
      "REACT_APP_URL_API": process.env.REACT_APP_URL_API,
    },
  }
};