import React, { useState } from 'react'
import { StyleSheet, KeyboardAvoidingView } from 'react-native'
import axios from 'axios'

import {
  Background,
  Logo,
  Header,
  ButtonCostum,
  TextInput,
  DialogCostum
} from '../components'

import { nameValidator } from '../helpers/nameValidator'
import { emailValidator } from '../helpers/emailValidator'

export default function ResetPasswordScreen({ navigation }) {
  const [visible, setVisible] = useState(false)
  const hideDialog = () => setVisible(false)
  const [email, setEmail] = useState({ value: '', error: '' })
  const [name, setName] = useState({ value: '', error: '' })
  const [errorMessage, setErrorMessage] = useState(null)

  const sendResetPasswordEmail = async () => {
    const nameError = nameValidator(name.value)
    const emailError = emailValidator(email.value)
    if (nameError || emailError) {
      setName({ ...name, error: nameError })
      setEmail({ ...email, error: emailError })
      return
    }
    const postData = {
      username: name.value,
      email: email.value,
      code_apps: 'SP',
    };
    
    axios.defaults.headers.common['Authorization'] = "";
    // navigation.reset({
    //   index: 0,
    //   routes: [{ name: 'HomeScreen' }],
    // })
    try {
      await axios.post( process.env.REACT_APP_URL_API + "webarb/login/login",  postData, {timeout: 120000})
      .then(res => {
        console.log(res);

        if (res.data.data){
            setErrorMessage(res.data.message);
            setVisible(true);
        }else{
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }

      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
  }

  return (
    <Background>
      <KeyboardAvoidingView style={styles.container} behavior="padding">
        <Logo />
        <Header>Lupa Kata Sandi</Header>
        <TextInput
          isFocus={true}
          label="Nama Pengguna"
          returnKeyType="next"
          value={name.value}
          onChangeText={(text) => setName({ value: text, error: '' })}
          error={!!name.error}
          errorText={name.error}
        />
        <TextInput
          label="E-mail"
          returnKeyType="done"
          value={email.value}
          onChangeText={(text) => setEmail({ value: text, error: '' })}
          error={!!email.error}
          errorText={email.error}
          autoCapitalize="none"
          autoCompleteType="email"
          textContentType="emailAddress"
          keyboardType="email-address"
          description="Link Reset Kata Sandi akan dikirim ke Email Anda."
        />
        <ButtonCostum 
          mode="contained"
          onPress={sendResetPasswordEmail}
          style={{ marginTop: 16 }}
        >
          Kirim
        </ButtonCostum>
      </KeyboardAvoidingView>
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 50,
    padding: 10,
    maxWidth: 340,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
