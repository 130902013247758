import React, { useState, useEffect, useContext } from 'react'
import { Avatar, List, Divider, Title, Card, Button } from 'react-native-paper'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  KeyboardAvoidingView,
} from 'react-native'
import axios from 'axios'
import FontAwesome from '@expo/vector-icons/FontAwesome'

import { LoginContext } from '../contexts/GlobalState'
import {
  Background,
  Header,
  DialogCostum
} from '../components'
import { theme } from '../core/theme'

export default function SessionScreen() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [visible, setVisible] = useState(false)
  const [button, setButton] = useState()
  const [rawData, setRawData] = useState([])

  const context = useContext(LoginContext)  
  useEffect(() => {
    getSessions()
  }, [context]);

  const hideDialog = () => {
    setVisible(false)
  }
  const getSessions = () => {
    setVisible(false)
    try {
      axios.post( process.env.REACT_APP_URL_API + "MNPM0013/read",  {code_apps:'SP',orderby:'usrnm',methods:'get_data',tampil:'10',user:context.username}, {timeout: 120000})
      .then(res => {
        console.log(res);

        if (res.data.data){
          setRawData(res.data.data)
        }else{
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }

      })
      .catch(err => {
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }
  }

  return (
    <Background>
      {/* <Header><FontAwesome name='clock-o' size={24} color={theme.colors.primary}></FontAwesome> Sesi Pengguna</Header> */}
      <List.Item
        style={styles.gridView}
        title={context.fullname}
        description={context.branch+' - '+context.officename}
        left={props => <Avatar.Text size={45} label={context.fullname.substring(0, 2)} />}
      />

      <Card style={styles.gridView}>
        <Card.Content>
          <Title>Sesi Aktif</Title>
          <Text>Pilih untuk keluar aplikasi</Text>
          <View>
          <Divider />
          {rawData?.gridapps && rawData?.gridapps.map((row,idx) => (
            <View key={row.id}>
              <Divider />
              <List.Item
                title={row.descr}
                description={`Terakhir Masuk ${row.last_login}`}
                right={() => <FontAwesome
                  name="sign-out"
                  style={{alignSelf:'center'}}
                  onPress={()=>{
                    setErrorMessage("Terakhir masuk "+row.descr+" : "+row.last_login+` \n Hentikan sesi?`)
                    setVisible(true)

                    setButton(
                      <>
                        <Button onPress={()=>{

                          try {
                            axios.post( process.env.REACT_APP_URL_API + "MNPM0013/update",  {code_apps:row.id}, {timeout: 120000})
                            .then(res => {
                              console.log(res);

                              if (res.data.data){
                                getSessions()
                              }else{
                                if(res.data.error.responseCode){
                                  setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
                                }else if(res.data.error.message){
                                  setErrorMessage(res.data.error.message);
                                }else{
                                  setErrorMessage(res.data.error);
                                }
                              }

                            })
                            .catch(err => {
                              console.log(err);
                              setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
                            });
                          } catch (err) {
                            console.log(err);
                            setErrorMessage('Server Luring');
                          }

                        }}>Ya</Button>
                        <Button onPress={hideDialog}>Tidak</Button>
                      </>
                    )

                  }}
                  color={theme.colors.primary}
                  size={20} 
                />}
              />
              {idx >0 && <Divider />}
            </View>
          ))}

          </View>
        </Card.Content>
      </Card>

      <DialogCostum props={{visible, hideDialog, message:errorMessage, button}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 10,
  },
});
