import React, { useState, useRef, useEffect, useContext } from 'react'
import { Card, Portal } from 'react-native-paper'
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
} from 'react-native'
import axios from 'axios'
import { FlatGrid } from 'react-native-super-grid'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import { Picker } from '@react-native-picker/picker'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { VictoryBar, VictoryLabel, VictoryChart, VictoryAxis } from 'victory';

import Background from '../components/Background'
import { LoginContext } from '../contexts/GlobalState'
// utils
import { currencyAbbreviate } from '../utils/currency';
import {
  DialogCostum
} from '../components'

export default function HomeScreen({ route, navigation }) {  
  const [visible, setVisible] = useState();
  
  const [selectedOfficeOrDate, setSelectedOfficeOrDate] = useState();
  const [currendt, setCurrendt] = useState();
  const [isOffice, setIsOffice] = useState(false);
  const [listOffice, setListOffice] = useState();
  const [listHomeGraphs, setListHomeGraphs] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isDateTrue, setIsDateTrue] = useState();
  
  const [selectedOffice, setSelectedOffice] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedPrevOffice, setSelectedPrevOffice] = useState();
  const [selectedPrevDate, setSelectedPrevDate] = useState();
  const datepickerRef = useRef(null);

  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }

  const hideDialog = () => {
    setVisible(false)
  }

  const context = useContext(LoginContext)  
  useEffect(() => {}, [context]);
  const defaultBranch = (context.console??context.branch)
  // const defaultBranch = (context.branch===context.pusat?context.console:context.branch)
  
  useEffect(() => {
    
    if((selectedOffice!==selectedPrevOffice || selectedDate!==selectedPrevDate) || !selectedOffice)
      try {
        
        axios.get( process.env.REACT_APP_URL_API + "SPRP0001/read",  {
          params:{
            code_apps:'SP',
            oprdt:selectedDate,
            brncd:selectedOffice?selectedOffice:defaultBranch
          }
        }, {timeout: 120000})
        .then(res => {

          if (!res.data.data){
            if(res.data.error.responseCode){
              setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
            }else if(res.data.error.message){
              setErrorMessage(res.data.error.message);
            }else{
              setErrorMessage(res.data.error);
            }
            setVisible(true);
          }else{
            if(res.data.data?.accessoffice)
              setListOffice(res.data.data.accessoffice);

            if(!selectedDate && res.data.data?.oprdt){
              setSelectedPrevDate(new Date(res.data.data.oprdt))
              // setSelectedDate(new Date(res.data.data.oprdt))
              setCurrendt(new Date(res.data.data.oprdt))
            }

            setIsDateTrue(false)
            if(res.data.data?.homegraphs?.data.message){
              // setSelectedOffice((previous) => console.log(previous))
              // setSelectedDate((previous) => previous.value + 1)
              setSelectedOffice(selectedPrevOffice)
              // setSelectedDate(selectedPrevDate)
              setErrorMessage(res.data.data.homegraphs.data.message)
              setVisible(true)
            }else{
              setSelectedPrevOffice(selectedOffice)
              setSelectedPrevDate(selectedDate)
              setListHomeGraphs(res.data.data?.homegraphs)
              setIsDateTrue(true)
            }

            if(!selectedOffice){
              setSelectedPrevOffice(defaultBranch)
              // setSelectedOffice(context.branch)
            }

            setIsOffice(true)
          }

          var oriOffice = !selectedOffice?res.data.data?.brnch:selectedOffice
          var brnm
          var ofc = res.data.data?.accessoffice?res.data.data?.accessoffice:listOffice;
          ofc && ofc.map(function(o, i){
            if(oriOffice == o.brncd)
              brnm= o.brnm;
          })
          
          navigation.setParams({
            access: ofc,
            brnm: brnm,
            oprdt: !selectedDate? res.data?.data?.oprdt: selectedDate,
            brncd: oriOffice,
          });

        })
        .catch(err => {
          console.log(err);
          setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
          setVisible(true);
        });
      } catch (err) {
        console.log(err);
        setErrorMessage('Server Luring');
        setVisible(true);
      }
    
  }, [selectedOfficeOrDate]);

  const items = [
    { name: 'Dashboard', screen: 'Dashboard', icon: 'dashboard' },
    { name: 'M-Approval', screen: 'M-Approval', icon: 'check-square-o' },
    { name: 'M-Collection', screen: 'M-Collection', icon: 'diamond' },
    { name: 'M-Info', screen: 'M-Info', icon: 'file-text-o' },
  ];

  const ndate = new Date();
  const hours = ndate.getHours();
  var selamat;
  
  if (hours>=1 && hours<11){
    selamat = 'Selamat Pagi';
  } else if (hours>=11 && hours<15) {
    selamat = 'Selamat Siang';
  } else if (hours>=15 && hours<19) {
    selamat = 'Selamat Sore';
  } else {
    selamat = 'Selamat Malam';
  }

  return (<ImageBackground style={styles.background}>

    {
      isOffice && <>
        
        {
          window.innerWidth <= 500 && <View style={{paddingTop:10}}>
            <Text style={{textAlign: "center", padding:5, fontSize:18, fontWeight:'bold'}}>{context.bankname}</Text>
          </View>
        }

        <View style={{paddingBottom:5, zIndex:1000}}>
          <View style={[styles.mncontainer, {padding:5}]}>
            
            <View style={[window.innerWidth > 500? styles.mncontainer:"", {flexDirection: 'row', padding:4, paddingLeft:8}]} >
              <Text style={{fontWeight:'bold'}}>Kantor : </Text>
              <Picker
                selectedValue={selectedOffice?selectedOffice:defaultBranch}
                onValueChange={(itemValue, itemIndex) => {
                  setSelectedOffice(itemValue)
                  setSelectedOfficeOrDate(itemValue)
                }}>
                  
                {
                  listOffice && listOffice.map(function(o, i){
                    return <Picker.Item label={o.brncd+' - '+o.brnm} key={o.brncd}  value={o.brncd} />;
                  })
                }
              </Picker>
            </View>
            
            <View style={{flexDirection: 'row', padding:0}} >
              <Text style={{fontWeight:'bold', width:92}}>Tanggal :</Text>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={selectedDate?selectedDate:currendt}
                onChange={(date) => {
                  setSelectedDate(date)
                  setSelectedOfficeOrDate(date)
                }}
                ref={datepickerRef} 
              />
              <FontAwesome onPress={() => handleClickDatepickerIcon()} name="calendar" size={16} style={{position:'relative', right:20}}></FontAwesome>
            </View>
          </View>
        </View>
      </>
    }
      
    <Background>

      { isDateTrue &&
        <View style={{zIndex:-100}}>
          {
            (listHomeGraphs && listHomeGraphs.data) && (
              listHomeGraphs.data.map(function(i, k) {
                return <ChartGroupItems key={k} id={k} data={i} />
              })
          )}
        </View> 
      }

      <Portal>
        <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
      </Portal>
    </Background>
    </ImageBackground>
  )
}

const NegativeAwareTickLabel = props => {
  const { datum, y, dy, scale, ...rest } = props;
  
  return (
    <VictoryLabel
      datum={datum}
      y={datum.y<0 ? scale.y(0): y}
      dy={datum.y<0 ? 20 * Math.sign(datum.y): dy}
      {...rest}
    />
  );
};

const ChartGroupItems = ({ id, data }) => {
  const [toggleThisElement, setToggleThisElement] = useState(false);
  return (
    <Card style={{backgroundColor: 'rgba(204, 204, 255, 0.2)', margin:10}}>

      <Card.Content style={toggleThisElement?{height:70}:""}>
        <View style={{flexDirection: 'row', padding:10}}>
          <FontAwesome onPress={() => setToggleThisElement((prev) => !prev)} name={toggleThisElement?'caret-down':'caret-up'} size={16} color={'black'}></FontAwesome>
          <Text style={{fontWeight:'bold', paddingLeft:10, textDecoration: 'underline'}}>{data[0][0].groups}</Text>
        </View>
        <FlatGrid
          itemDimension={380}
          data={data}
          style={[styles.gridView,{visibility:toggleThisElement?"hidden":"visible"}]}
          spacing={10}
          renderItem={({ item }) => (
            <Card style={{backgroundColor: 'rgba(204, 204, 255, 0.7)'}}>

              <Card.Content>
                <View style={{flexDirection: 'row', padding:10}}>
                  <FontAwesome name={'bar-chart'} size={16} color={'black'}></FontAwesome>
                  <Text style={{fontWeight:'bold', paddingLeft:10, textDecoration: 'underline'}}>{item[0].descr}</Text>
                </View>
                <View>
                  <VictoryChart
                    responsive={false}
                    animate={{
                      duration: 500,
                      onLoad: { duration: 200 }
                    }}
                    domainPadding={{x: 60, y: 5}}
                  >
                    <VictoryBar
                      barRatio={0.8}
                      cornerRadius={0} 
                      alignment="middle"
                      labels={d => d.is_ratio ? d.y+"%": currencyAbbreviate(d.y)}
                      style={{
                        data: {
                          fill: ({ color }) => color
                        },
                        labels: {
                          fill: ({ y, val_red }) => {
                            
                            if(val_red){
                              var cal = val_red.split(";");

                              switch (cal[0]) {
                                case "<":
                                      
                                  if(y< cal[1]){
                                    return "red"
                                  }
                                  break;
                                case ">":
                                      
                                  if(y> cal[1]){
                                    return "red"
                                  }
                                  break;
                              
                                default:
                                  if(y != cal[1]){
                                    return "red"
                                  }
                                  break;
                              }
                            }

                          }
                        }
                      }}
                      labelComponent={<NegativeAwareTickLabel />}
                      data={item}
                    />
                    <VictoryAxis tickFormat={(t) => t.split(" ")[1]} />
                    <VictoryAxis dependentAxis={true} tickFormat={(t) => currencyAbbreviate(t)}
                      style={{
                        tickLabels: {fontSize: 10},
                      }}
                    />
                  </VictoryChart>
                </View>
                <View style={{flexDirection: 'row', width: '100%'}}>
                  <Text style={{fontWeight:'bold', paddingLeft:10, textDecoration: 'underline'}}>Pertumbuhan</Text>
                  <View style={{flexDirection: 'row', marginLeft: 'auto', paddingRight:100}}>
                    {
                      item && 
                        item.map(function(i, k) {
                          if(k>0 && i.y){
                            if(!i.is_ratio)
                              var total = (((i.y-item[k-1].y)/item[k-1].y) *100).toFixed(2) 
                            else
                              var total = (i.y-item[k-1].y).toFixed(2) 
                            
                            var icon = 'minus'
                            var tumbuh = ''
                            if(i.y && item[k-1].y)
                              if(i.y < item[k-1].y){
                                icon = 'caret-down'
                                tumbuh = 'D'
                              }else if(i.y > item[k-1].y){
                                icon = 'caret-up'
                                tumbuh = 'U'
                              }

                            return <View key={id+''+k} style={{flexDirection: 'row', paddingLeft:70}}>
                              <FontAwesome name={icon} size={16} color={i.color} style={{paddingRight:5}}></FontAwesome>
                              {
                                ( total !== 'NaN' && total !== 'Infinity' && total !== '-Infinity') ?
                                  <Text style={{color:(tumbuh===i.growth_red?'red':'black')}}>{total.replaceAll('-','')}%</Text>
                                :
                                <Text style={{color:(tumbuh===i.growth_red?'red':'black')}}>0.00%</Text>
                              }
                            </View>
                          }
                        })
                    }
                    
                  </View>
                </View>
              </Card.Content>
            </Card>
          )}
        />
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  gridView: {
    marginTop: 10,
  },
  background: {
    flex: 1,
    resizeMode: 'cover', 
  },
  mncontainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start' 
  },
  itemContainer: {
    borderRadius: 15,
    padding: 10,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemName: {
    paddingTop: 6,
    fontSize: 14,
    color: '#fff',
    fontWeight: '600',
  },
});
