import React, { useState } from 'react'
import { Button, Dialog, Portal } from 'react-native-paper'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import Paragraph from './Paragraph'

export default function DialogCostum({ props }) {
  return (
    <Portal>
      <Dialog visible={props.visible} onDismiss={props.hideDialog} style={{ maxWidth: 500, alignSelf: "center" }} >
        <Dialog.Content>
          {/* <Paragraph><FontAwesome name='warning' size={24} color="orange" ></FontAwesome></Paragraph> */}
          <Paragraph>{props.message}</Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          {
            props.button ? props.button : <Button onPress={props.hideDialog}>Ok</Button>
          }
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}