import React, { useEffect, useContext } from 'react'
import { Avatar, List, Divider, Title, Card } from 'react-native-paper'
import {
  View,
  StyleSheet,
} from 'react-native'
import FontAwesome from '@expo/vector-icons/FontAwesome'

import { LoginContext } from '../contexts/GlobalState'
import Background from '../components/Background'
import { theme } from '../core/theme'

export default function AccountScreen() {
  const context = useContext(LoginContext)  
  useEffect(() => {}, [context]);
  
  return (
    <Background>
      {/* <Header><FontAwesome name='user' size={24} color={theme.colors.primary}></FontAwesome> Akun</Header> */}
      <List.Item
        style={styles.gridView}
        title={context.fullname}
        description={context.branch+' - '+context.officename}
        left={props => <Avatar.Text size={45} label={context.fullname.substring(0, 2)} />}
      />

      <Card style={styles.gridView}>
        <Card.Content>
          <Title><FontAwesome name='group' size={16} color={theme.colors.primary}></FontAwesome> Informasi Umum</Title>
          <View>
            <Divider />
            <List.Item
              title="Inisial Pengguna"
              description={context?.initnm}
            />
            <Divider />
            <List.Item
              title="Nama Pengguna"
              description={context.username}
            />
            <Divider />
            <List.Item
              title="Nama Lengkap"
              description={context.fullname}
            />
            <Divider />
            <List.Item
              title="Email"
              description={context.email}
            />
          </View>
        </Card.Content>
      </Card>

      <Card style={styles.gridView}>
        <Card.Content>
          <Title> <FontAwesome name='briefcase' size={16} color={theme.colors.primary}></FontAwesome> Informasi Kepegawaian</Title>
          <View>
            <Divider />
            <List.Item
              title="Kantor"
              description={context.branch+' - '+context.officename}
            />
            <Divider />
            <List.Item
              title="Status"
              description="Aktif"
              // description={user?.data?.status==='Y'?'Aktif':''}
            />
            <Divider />
            <List.Item
              title="Departemen"
              description={context.department}
            />
            <Divider />
            <List.Item
              title="Level"
              description={context.level}
            />
          </View>
        </Card.Content>
      </Card>

    </Background>
  )
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 10,
  },
});
