import React from 'react'
import { ScrollView } from "react-native";
import ReactDOM from 'react-dom';
import { VictoryBar, VictoryLabel, VictoryLegend, VictoryGroup, VictoryChart, VictoryAxis, VictoryTheme, VictoryStack } from 'victory';
import Background from '../../components/Background'

export default function Home({ navigation }) {
  return (
    <Background>
      <ScrollView contentInsetAdjustmentBehavior="automatic" >
        <VictoryChart>

          <VictoryLabel text="Chart Title"
              x={225} y={10}
              textAnchor="middle"
          />
          <VictoryLegend x={125} y={18}
            orientation="horizontal"
            gutter={20}
            style={{ border: { stroke: "black" } }}
            colorScale={"qualitative"}
            data={[
              { name: "One" }, { name: "Two"}, { name: "Three" }
            ]}
          />
          <VictoryGroup offset={20}
            colorScale={"qualitative"}
          >
            <VictoryBar
              data={[{ x: 'Konsolidasi', y: 1 }, { x: "Pusat", y: 7 }, { x: "Cabang", y: 9 }]}
              labels={["1","7","9"]}
              labelComponent={<VictoryLabel dy={-5} />}
            />
            <VictoryBar
              data={[{ x: 'Konsolidasi', y: 2 }, { x: "Pusat", y: 4 }, { x: "Cabang", y: 7 }]}
              labels={["2","4","7"]}
              labelComponent={<VictoryLabel dy={-5} />}
            />
            <VictoryBar
              data={[{ x: 'Konsolidasi', y: 3 }, { x: "Pusat", y: 4 }, { x: "Cabang", y: 5 }]}
              labels={["3","4","5"]}
              labelComponent={<VictoryLabel dy={-5} />}
            />
          </VictoryGroup>
          <VictoryAxis
            tickValues={['Konsolidasi','Pusat','Cabang']}
            minDomain={{ x: 0 }}
          />
          <VictoryAxis dependentAxis={true} />
        </VictoryChart>
        <VictoryChart>
          <VictoryLabel text="Chart Title"
              x={225} y={10}
              textAnchor="middle"
          />
          <VictoryLegend x={125} y={18}
            orientation="horizontal"
            gutter={20}
            style={{ border: { stroke: "black" } }}
            colorScale={"qualitative"}
            data={[
              { name: "One" }, { name: "Two"}, { name: "Three" }
            ]}
          />
          <VictoryGroup offset={20}
            colorScale={"qualitative"}
          >
            <VictoryBar
              data={[{ x: 'Konsolidasi', y: 1 }, { x: "Pusat", y: 7 }, { x: "Cabang", y: 9 }]}
              labels={["1","7","9"]}
              labelComponent={<VictoryLabel dy={-5} />}
            />
            <VictoryBar
              data={[{ x: 'Konsolidasi', y: 2 }, { x: "Pusat", y: 4 }, { x: "Cabang", y: 7 }]}
              labels={["2","4","7"]}
              labelComponent={<VictoryLabel dy={-5} />}
            />
            <VictoryBar
              data={[{ x: 'Konsolidasi', y: 3 }, { x: "Pusat", y: 4 }, { x: "Cabang", y: 5 }]}
              labels={["3","4","5"]}
              labelComponent={<VictoryLabel dy={-5} />}
            />
          </VictoryGroup>
          <VictoryAxis
            tickValues={['Konsolidasi','Pusat','Cabang']}
            minDomain={{ x: 0 }}
          />
          <VictoryAxis dependentAxis={true} />
        </VictoryChart>
      </ScrollView>
    </Background>
  )
}