import React, { useState } from 'react'
import { View, Text, Image } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { Picker } from '@react-native-picker/picker'

import {
  StartScreen,
  LoginScreen,
  RegisterScreen,
  ForgotScreen,
  ResetPWDScreen,
} from '../screens'
import TabBottomStack from './TabBottomStack'
import DrawerStack from './DrawerStack'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import { theme } from '../core/theme'

export const Stack = createStackNavigator()

export default function Container() {
  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName={"Sinergi Apps"}
        screenOptions={{
          // headerShown: false,
          headerTransparent: true,
        }}
      >
        <Stack.Screen name=" " component={LoginScreen}  options={{
          headerStyle: {
              backgroundColor: theme.colors.secondary
          },  
          //headerTitle : () => <BarHeaderIcon icon="diamond" title="Sinergi Apps" />,
          //headerRight : () => <BarRightHeader />,
        }}/>
        <Stack.Screen name="Login"
          options={{
            title: 'Masuk',
          }}  component={LoginScreen} />
        <Stack.Screen name="Sign Up" component={RegisterScreen} />
        <Stack.Screen name="Forgot Password" 
          options={{
            title: 'Lupa Kata Sandi',
          }} component={ForgotScreen} />
        <Stack.Screen name="New Password"
          options={{
            title: 'Reset Kata Sandi',
          }} component={ResetPWDScreen} />
        {/* <Stack.Screen name="Home" component={TabBottomStack} options={{headerShown: false, title: 'Beranda',}}/> */}
        <Stack.Screen name="Home" component={DrawerStack} options={{headerShown: false, title: 'Beranda', }}/>
        
        {/* <DrawerStack /> */}
      </Stack.Navigator>
    </NavigationContainer>
  )
}

function BarRightHeader(props) {
  const [selectedLanguage, setSelectedLanguage] = useState();
  return (
    <View style={{ flexDirection: 'row', paddingRight:15 }} >
      <FontAwesome name="language" size={16} style={{paddingRight:5}}></FontAwesome>
      <Picker
        selectedValue={selectedLanguage}
        onValueChange={(itemValue, itemIndex) =>
          setSelectedLanguage(itemValue)
        }>
        <Picker.Item label="Indonesia" value="id" />
        <Picker.Item label="English" value="en" />
      </Picker>
    </View>
  );
}

function BarHeaderIcon(props) {
  return (
    <View style={{ flexDirection: 'row' }} >
      <Image style={{ paddingRight:40, width: 25, height: 25 }} source={require('../assets/imgs/logo/dashboard-logo.png')} />
      {/* <FontAwesome name={props.icon} color={theme.colors.primary} size={20} style={{paddingRight:10}}></FontAwesome> */}
      <Text style={{fontWeight: 'bold', color:theme.colors.primary, fontSize:18 }}>
        {props.title}
      </Text>
    </View>
  );
}