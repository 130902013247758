import React, { useState, useEffect } from 'react'
import { ScrollView, View, StyleSheet, KeyboardAvoidingView } from "react-native"
import { Divider, Card, Button, TextInput as Input } from 'react-native-paper'
import axios from 'axios'
import { Picker } from '@react-native-picker/picker'
import DropDown from "react-native-paper-dropdown"

import { theme } from '../../core/theme'
import {
  TextInput,
  DialogCostum,
  Loader,
} from '../../components'
import Background from '../../components/Background'

export default function SPRP0015({ route, navigation }) {
  const homeprm = navigation.getState();
  const home = homeprm.routes[0].state.routes[0].params;
  // const date = new Date() // 16/01/2024 read date current not oprdt
  const date = new Date(home.oprdt) // 06/09/2024 balik lg
  const [errorMessage, setErrorMessage] = useState(null)
  const [visible, setVisible] = useState(false)
  const [spin, setSpin] = useState()

  const hideDialog = () => {
    setVisible(false)
  }

  const [onShown, setOnshown] = useState(true)
  const [year, setYear] = useState({ value: date.getFullYear(), error: '' })
  const [metode, setMetode] = useState({ value: 1, error: '' })
  const [grup, setGrup] = useState({ value: 1, error: '' })
  const [duedate, setDuedate] = useState({ value: 12, error: '' })
  const [month, setMonth] = useState({ value: date.getMonth(), error: '' })
  const [format, setFormat] = useState({ value: 'xlsx', error: '' })
  
  const [grupDropDown, setGrupDropDown] = useState(false);
  const [duedateDropDown, setduedateDropDown] = useState(false);
  const [monthDropDown, setmonthDropDown] = useState(false);
  const [metodeDropDown, setMetodeDropDown] = useState(false);
  const [formatDropDown, setFormatDropDown] = useState(false);

  const [months, setmonths] = useState({
    list:[],
    triw:[],
  });

  const metodes = { 
    list:[
      { label: "PD Net Flow", value: 1 },
      { label: "PD Migration", value: 2 },
    ]
  }

  const grups = { 
    list:[
      { label: "Jenis Penggunaan", value: 1 },
      { label: "Kategori Usaha", value: 2 },
    ]
  }

  const duedates = { 
    list:[
      { label: "12 Bulan", value: 12 },
      { label: "24 Bulan", value: 24 },
      { label: "36 Bulan", value: 36 },
      { label: "48 Bulan", value: 48 },
      { label: "60 Bulan", value: 60 }
    ]
  }

  const formats = { 
    list:[
      { label: "Excel", value: 'xlsx' },
      { label: "CSV", value: 'csv' },
    ]
  }
  const onCancel = () => {    
    setYear({ value: date.getFullYear(), error: '' })
    setGrup({ value: 1, error: '' })
    setMetode({ value:1, error: ''})
    setDuedate({ value:12, error:''})
    setOnshown(true)
  }

  const yearValidator = (v) => {
    if (!v) return "Periode Tahun tidak boleh kosong."
    if (parseInt(v)<1000 || parseInt(v)>9999 || isNaN(parseInt(v))) return "Periode Tahun tidak valid."
    if (v>date.getFullYear()) return "Periode Tahun tidak boleh lebih besar dari "+date.getFullYear()+"."
    return ''
  }

  const metodeValidator = (x) => {
    if (!x) return "Metode PD tidak boleh kosong."
    return ''
  }
  
  const formatValidator = (v) => {
    if (!v && v!==0) return "Cetak Ke tidak boleh kosong."
    return ''
  }

  const monthsOnChange = (val) => {
    var list = [
      { label: "Januari", value: 0 },
      { label: "Februari", value: 1 },
      { label: "Maret", value: 2 },
      { label: "April", value: 3 },
      { label: "Mei", value: 4 },
      { label: "Juni", value: 5 },
      { label: "Juli", value: 6 },
      { label: "Agustus", value: 7 },
      { label: "September", value: 8 },
      { label: "Oktober", value: 9 },
      { label: "November", value: 10 },
      { label: "Desember", value: 11, disabled: true },
    ]

    var triw = [
      { label: "Maret", value: 2 },
      { label: "Juni", value: 5 },
      { label: "September", value: 8 },
      { label: "Desember", value: 11 },
    ]

    if(val>date.getFullYear()){
      setMonth({ value: '', error: '' })
    }

    setmonths({ 
        list: val>date.getFullYear()?[]:(val<date.getFullYear()?list:list.filter((v) => 
          v.value<=date.getMonth()
        )),
        triw: val>date.getFullYear()?[]:(val<date.getFullYear()?triw:triw.filter((v) => 
          v.value<=date.getMonth()
        )),
      })
  }

  useEffect(() => {
    monthsOnChange(date.getFullYear())
  }, []);


  const metodeOnChange = (val) => {
    setMetode({ value: val, error: '' })
    if (val=='2'){
      var triw
      if(date.getMonth() < 2){
        triw = 2
      }else if(date.getMonth() < 5){
        triw = 5
      }else if(date.getMonth() < 8){
        triw = 8
      }else{
        triw = 11
      }
      setMonth({ value: triw, error: '' })
    }else{
      setMonth({ value: date.getMonth(), error: '' })
    }
    // if (val=='2'){
    //   setOnshown(false)
    // }else{
      setOnshown(true)
    // }
    return '' 
  }

  const grupValidator = (y) => {
    if (!y) return "Grup PD tidak boleh kosong."
    return ''
  }

  const onSubmit = async () => {
    const yearError = yearValidator(year.value)
    const metodeError = metodeValidator(metode.value)
    const grupError = grupValidator(grup.value)
    const formatError = formatValidator(format.value)

    setYear({ ...year, error: yearError })
    setMetode({ ...metode, error: metodeError })
    setGrup({ ...grup, error: grupError })
    setFormat({ ...format, error: formatError })
    if (yearError || metodeError || grupError || formatError) {
      return
    }

    setSpin(true)
    
    try {
      await axios.post(process.env.REACT_APP_URL_API + "SPRP0015/read",  {
        year:year.value,
        grup:grup.value,
        duedate:duedate.value,
        month:month.value,
        format:format.value,
        method:metode.value
      }, {timeout: 600000})
      .then(res => {
        
        setSpin(false)

        if (!res.data.data){
          if(res.data.error.responseCode){
            setErrorMessage(`${res.data.error.responseCode} - ${res.data.error.responseMsg}`);
          }else if(res.data.error.message){
            setErrorMessage(res.data.error.message);
          }else{
            setErrorMessage(res.data.error);
          }
          setVisible(true);
        }else{
          var linkSource = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data?.data?.content;
          var downloadLink = document.createElement("a");

          downloadLink.href = linkSource;
          downloadLink.download = res.data?.data?.filenm;
          downloadLink.click();

          if(res.data?.data?.path) window.location.href = process.env.REACT_APP_URL_API + res.data.data.path;
        }
      })
      .catch(err => {
        setSpin(false)
        console.log(err);
        setErrorMessage(err.code==="ECONNABORTED"? "Server Luring": (err.message?err.message:JSON.stringify(err)) );
        setVisible(true);
      });
    } catch (err) {
      setSpin(false)
      console.log(err);
      setErrorMessage('Server Luring');
      setVisible(true);
    }

  }

  return (
    <Background>
      <ScrollView>
        <KeyboardAvoidingView style={styles.container} behavior="padding">
          <View>
            <Card style={styles.gridView}>
              <Card.Title title="Parameter" />
              <Divider />
              <Card.Content>
                <View>
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Metode PD"}
                      mode={"outlined"}
                      value={metode.value}
                      setValue={(text) => metodeOnChange(text)}
                      list={metodes.list}
                      error={!!metode.error}
                      errorText={metode.error}
                      visible={metodeDropDown}
                      showDropDown={() => setMetodeDropDown(true)}
                      onDismiss={() => setMetodeDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={metodeDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {metode.error ? <Text style={styles.error}>{metode.error}</Text> : null}
                  </View>
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Grup PD"}
                      mode={"outlined"}
                      value={grup.value}
                      setValue={(text) => setGrup({ value: text, error: '' })}
                      list={grups.list}
                      error={!!grup.error}
                      errorText={grup.error}
                      visible={grupDropDown}
                      showDropDown={() => setGrupDropDown(true)}
                      onDismiss={() => setGrupDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={grupDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {grup.error ? <Text style={styles.error}>{grup.error}</Text> : null}
                    </View>
                    {onShown && 
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Jangka Waktu"}
                      mode={"outlined"}
                      value={duedate.value}
                      setValue={(text) => setDuedate({ value: text, error: '' })}
                      list={duedates.list}
                      error={!!duedate.error}
                      errorText={duedate.error}
                      visible={duedateDropDown}
                      showDropDown={() => setduedateDropDown(true)}
                      onDismiss={() => setduedateDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={duedateDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {duedate.error ? <Text style={styles.error}>{duedate.error}</Text> : null}
                    </View>
                  }
                  <TextInput 
                    label="Periode Tahun"
                    returnKeyType="next"
                    value={year.value}
                    onChangeText={(text) => {monthsOnChange(text); setYear({ value: text.replace(/[^0-9]/g, ''), error: '' })}}
                    // error={!!year.error}
                    errorText={year.error}
                  />
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Bulan"}
                      mode={"outlined"}
                      value={month.value}
                      setValue={(text) => setMonth({ value: text, error: '' })}
                      list={metode.value===2?months.triw:months.list}
                      error={!!month.error}
                      errorText={month.error}
                      visible={monthDropDown}
                      showDropDown={() => setmonthDropDown(true)}
                      onDismiss={() => setmonthDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={monthDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {duedate.error ? <Text style={styles.error}>{duedate.error}</Text> : null}
                    </View>
                  <View style={styles.dropDown}>
                    <DropDown
                      label={"Cetak Ke"}
                      mode={"outlined"}
                      value={format.value}
                      setValue={(text) => setFormat({ value: text, error: '' })}
                      list={formats.list}
                      error={!!format.error}
                      errorText={format.error}
                      visible={formatDropDown}
                      showDropDown={() => setFormatDropDown(true)}
                      onDismiss={() => setFormatDropDown(false)}
                      inputProps={{
                        right: <Input.Icon name={formatDropDown?"menu-up":"menu-down"} />,
                      }}
                    />
                    {format.error ? <Text style={styles.error}>{format.error}</Text> : null}
                  </View>
                </View>
              </Card.Content>
              <Divider />
              <Card.Actions style={{justifyContent: 'right'}}>
                <Button onPress={onCancel}>Batal</Button>
                <Button onPress={onSubmit}>Cetak</Button>
              </Card.Actions>
            </Card>
            
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
      
      { spin && <Loader size="large" animating={spin} />}
      <DialogCostum props={{visible, hideDialog, message:errorMessage}} />
    </Background>
  )
}

const styles = StyleSheet.create({
  // container: {
  //   flex: 1,
  //   padding: 10,
  //   // maxWidth: 420,
  //   width: '100%',
  //   alignSelf: 'center',
  // },
  container: {
    flex: 1,
    paddingTop: 10,
    padding: 10,
    maxWidth: 340,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridView: {
    marginTop: 2,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
  dropDown: {
    marginVertical: 6,
    // flex: 1,
    // left:-100 , position: 'absolute',
  },
});